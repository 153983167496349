import { DataStorageService } from 'src/app/services/storage.service';
import { PaymentService } from 'src/app/services/payment.service';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CONSTANTS } from 'src/app/model/enums';
import { IPaymentLog } from 'src/app/model/requests/payment-log.model';
import { LoggerService } from 'src/app/services/logger.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class StripePaymentComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() feature: "Online Store" | "Offer sheet" | "Payment preferences";
  @Input() email: string;
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  @Output() nonceChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() purchase: BehaviorSubject<any>;
  @Input() type: 'card' | 'giftcard';
  @Input() isValidateCard: boolean;
  @Input() stripe:any;
  data: any;
  stripeElement: any = null;
  stripeCard: any = null;
  styleElement:any = {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  };
  farm: any;
  constructor(private route: ActivatedRoute, private logSvr: LoggerService, public paymentService: PaymentService, private storage: DataStorageService) { }

  ngOnChanges(): void {}

  async ngOnInit() {
    if(this.stripe) {
      this.stripeElement = this.stripe.elements();
      this.stripeCard = this.stripeElement.create("card", { style: this.styleElement });
      this.stripeCard.mount("#stripe-card-element");
    }
  }

  async ngAfterViewInit() {
  }
  async createCardElement() {

    this.stripeCard = this.stripeElement.create("card", { style: this.styleElement });
    // Stripe injects an iframe into the DOM
    this.stripeCard.mount("#stripe-card-element");
    // if(!this.isValidateCard) this.stripeCard.update({ disabled: true })
  }
  payWithCard():BehaviorSubject<any> {
    let res:BehaviorSubject<any> = new BehaviorSubject(null);
    let that = this;
    that.stripe.createToken(
          this.stripeCard
        ).then(result => {
        if (result.error) {
          res.next(result.error);
        } else {
          res.next(result.token);
        }
    });
      return res;
  };

}
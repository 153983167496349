import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from '../components/generic/toast/toast.component';
import { Router } from '@angular/router';
import { CONSTANTS } from '../model/enums';
import { SessionService } from './session.service';
import { Subject } from 'rxjs';

export class ToastNamedAction {
    label: string;
    disabled?: boolean;
    callback: () => void;
}

export class ToastConfig {
    text: string;
    actions?: ToastNamedAction[];
    isError?= false;
    duration?= 3000;
    closeX?= false;
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    eventsSubject: Subject<void> = new Subject<void>();
    farmSlug: string;

    constructor(private snackBar: MatSnackBar, private router: Router, private session: SessionService) {
        this.session.getFarm().subscribe((farm) => {
            if (farm) {
                this.farmSlug = farm.slug;
            }
        });
    }

    open(customConfig: ToastConfig, handleDismiss?: boolean) {
        const panelClass = ['fl-toast', 'padding-30'];
        if (customConfig.isError) {
            panelClass.push('fl-toast-warn');
        }
        if (customConfig.actions) {
            customConfig.actions.forEach((action, index, actions) => {
                actions[index] = {
                    label: action.label,
                    disabled: action.disabled,
                    callback: () => {
                        action.callback();
                        snackBarRef.dismiss();
                    }
                };
            });
        }
        const config: MatSnackBarConfig = {
            verticalPosition: 'top',
            panelClass,
            duration: customConfig.duration || 3000,
            data: {
                text: customConfig.text,
                actions: customConfig.actions,
                closeX: customConfig.closeX
            }
        };

        const snackBarRef = this.snackBar.openFromComponent(ToastComponent, config);
        // snackBarRef.afterOpened().subscribe(() => {
        //     snackBarRef.instance.text = customConfig.text;
        //     if (customConfig.closeX) {
        //       snackBarRef.instance.closeX = true;
        //     }

        //     snackBarRef.instance.actions = customConfig.actions;
        // });
        if (handleDismiss) {
            snackBarRef.afterDismissed().subscribe(() => {
                this.eventsSubject.next();
            });
        }

        return snackBarRef;
    }

    getEventSubject() {
        return this.eventsSubject;
    }

    openItemAddedToast(item, quantity, mininumMessage = null) {
        let toastTest = `<p>(${quantity}) ${item.name} added to your cart </p>` +
        (mininumMessage ? `<br><span class="text-link-color">${mininumMessage}</span>` : '');
        this.snackBar.openFromComponent(ToastComponent, {
            data: {
                text: toastTest,
            },
            verticalPosition: 'top',
            panelClass: ['fl-toast', 'add-item-toast'],
            duration: 3000,
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/model/enums';
import { DataStorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-order-success',
    templateUrl: './order-success.component.html',
    styleUrls: ['./order-success.component.less']
})
export class POSOrderSuccessPublicComponent implements OnInit {

    farmSlug: string;
    farmName: string;
    orderId: number;

    constructor(private session: SessionService, private route: ActivatedRoute, private router: Router,private storage: DataStorageService) { }

    ngOnInit() {
        // this.session.getFarm().subscribe((farm: Farm) => {
        //     if (farm) {
        //         this.farmSlug = farm.slug;
        //         this.farmName = farm.name;
        //     }else{
        //         //get public data from cache
        //         const slug = window.location.pathname.split('/')[1];
        //         const farm = this.storage.getFarm(slug);
        //         this.farmSlug = farm.slug;
        //         this.farmName = farm.name;
        //     }
        // });

        // this.route.paramMap.subscribe((paramMap) => {
        //     this.orderId = Number(paramMap.get(CONSTANTS.ROUTE_PARAM_KEYS.ORDER_ID));
        // });

        this.route.paramMap.subscribe((params) => {
            this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            const cartEntries = this.storage.getCartList(this.farmSlug) || [];
            const farm = this.storage.getFarm(this.farmSlug) || [];
            this.farmSlug = farm.slug;
            this.farmName = farm.name;
          });
    }

    viewOrder() {
        this.router.navigate([CONSTANTS.ROUTES.VIEW_ORDER(this.farmSlug, this.orderId)]);
    }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { BUTTON_COLOR_DEFAULT, CATALOG_ITEM_TYPE, CONSTANTS } from 'src/app/model/enums';
import { LayoutService } from 'src/app/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { OfferItem } from 'src/app/model/offer';
import { HttpErrorResponse } from '@angular/common/http';
import { IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { StoreService } from 'src/app/services/store.service';
import { DataStorageService } from 'src/app/services/storage.service';
import * as moment from 'moment';
import { OnlineStoreCartEntry } from 'src/app/model/online-store-cart-entry';
import { OnlineStoreCartService } from 'src/app/services/online-store-cart.service';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

declare global {
  interface Window {
    dataLayer: any[]
    clarity: any
  }
}

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.less']
})
export class POSStoreComponent implements OnInit, OnDestroy {

  labels = {
    btnAdd: 'Add',
    lblQuantity: 'Enter amount',
    lblLogout: 'Log out',
    lblView: 'View',
    lblNotStarted: 'This offer has not started',
    placeholderEnterAmount: 'Enter amount',
    btnDismiss: 'Dismiss',
  };
  CONSTANTS = CONSTANTS;
  COLOR_DEFAULT_BACKGROUND = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_BACKGROUND;
  COLOR_DEFAULT_TEXT = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_TEXT;

  farmSlug: string;
  offersheetId: number;
  offer: any = [];
  deliveryHeader: string;
  nextOrderWindow: string;
  statusHeader: string;
  statusClass: string;
  timeRemaining: string;
  message: string;
  items: any = [];
  isClosed = true;
  entries: any = [];
  arrIdChecked: any = [];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page: any = 1;
  totalItem: any = 0;
  categories : any = [];
  farmData:any;
  isReadonly: boolean;

  public statusIntervalId: number;
  private isListView = false;

  public noOffer = false;
  public noFarm = false;
  public metaCode: string;
  public isFinishedLoadingOfferData = false;
  CATALOG_ITEM_TYPE = CATALOG_ITEM_TYPE;
  private subscription: Subscription = new Subscription();
  private isUnsubscribe: boolean = false;
  constructor(public onlineStoreCartService: OnlineStoreCartService, public session: SessionService,
    public layout: LayoutService, private toast: ToastService, private storeService: StoreService, private router: Router,
    private route: ActivatedRoute, private storage: DataStorageService,private pipe: CurrencyPipe) {
  }

  ngOnInit() {
    this.subscription.add(
      this.route.paramMap.subscribe((params) => {
        this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
        this.storage.resetFarmStorage(this.farmSlug);
        let entries = this.storage.getCartList(this.farmSlug) || [];
        this.onlineStoreCartService.initEntries(entries);

        this.loadOffer(this.farmSlug);
        this.loadItemStore(this.farmSlug);
        // this.setIsListView(true);
      })
    );

    this.subscription.add(
      this.onlineStoreCartService.getEntriesSubscription()
        .subscribe(entries => {
          this.entries = entries;
        })
    );
    this.isUnsubscribe = this.router.url.indexOf("unsubscribe") != -1;
  }

  ngOnDestroy() {
    if (this.statusIntervalId) {
      window.clearInterval(this.statusIntervalId);
    }
    this.subscription.unsubscribe();
  }

  loadOffer(farmSlug: string) {
    const params = { ...{ token: farmSlug } };
    Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

    this.storeService.getInfoStore(params, true).subscribe((response: IOfferFullResponse) => {
      this.metaCode = response.meta.code;
      // check case if not offer
      if (!response.data || !response.data.offer || response.data.offer == null) {
        if (['48_1_f', '47_28_f', '47_27_f', '47_16_f'].includes(this.metaCode)) {
          this.noOffer = true;
        } else if (response.meta.code === '48_1_f') {
          this.storage.resetFarmStorage(this.farmSlug);
          this.noFarm = true;
        }
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
        return;
      }

      if (!response.data.offer.window) {
        response.data.offer.window = {
          open: null,
          close: null,
          delivery: null
        }
      }
      this.farmData = response.data.farm;
      var orderAmountMinimum = this.offer['orderamountminimum'];
      var message = this.offer['message'];
      this.offer = response.data.offer;
      this.offer['orderamountminimum'] = orderAmountMinimum;
      this.offer['message'] = message;
      this.session.setFarm(response.data.farm, farmSlug);
      this.session.setOffer(this.offer);

      this.storage.setFarm(response.data.farm, farmSlug);
      this.storage.setWindow(response.data.offer, farmSlug);
      
      window.dataLayer = []
      if (window.dataLayer) {
        const layer = window.dataLayer
        if (!layer.find((f: any) => f.id)) {
          window.dataLayer.push({
            'farmId': this.farmData.id
          })
          window.clarity('set', 'farmId', this.farmData.id)
        }
        if (!layer.find((f: any) => f.farmName)) {
          window.dataLayer.push({
            'farmName': this.farmData.name
          })
          window.clarity('set', 'farmName', this.farmData.name)
        }
        if (!layer.find((f: any) => f.email)) {
          window.dataLayer.push({
            'farmEmail': this.farmData.email
          })
          window.clarity('set', 'farmEmail', this.farmData.email)
        }
      }

      this.calculateDisplay(farmSlug);

      if(response.data && response.data.readOnly) {
        this.isReadonly = true;
      }
    }, (error: HttpErrorResponse) => {
      this.metaCode = error.error.meta.code;
      if (error.status === 404) {
        if (['48_1_f', '47_28_f', '47_27_f', '47_16_f'].includes(this.metaCode)) {
          this.session.setFarm(error.error.data.farm, farmSlug);
          this.noOffer = true;
        } else {
          this.storage.resetFarmStorage(this.farmSlug);
          this.storage.setFarm(error.error.data.farm, farmSlug);
          this.noFarm = true;
        }
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
      } else {
        console.error(error);
        this.storage.resetFarmStorage(this.farmSlug);
        this.noFarm = true;
      }
    }, () => {
      this.isFinishedLoadingOfferData = true;
    });
  }

  loadItemStore(farmSlug) {
    const params = { ...{ token: farmSlug }, ...{page: this.page, size: 40} };
    Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

    this.storeService.getItemStore(params, true).subscribe((response) => {

      this.offer['items'] = response.data.offer.items;
      this.offer['message'] = response.data.offer.message;
      this.offer['orderamountminimum'] = response.data.offer.orderamountminimum;
      this.session.setOffer(this.offer);
      this.storage.setWindowInfo({
        orderamountminimum: response.data.offer.orderamountminimum
      },farmSlug);

      /**
       * @author Danh Huynh
       * @description Keep format style for request TEND-14030
       */
      this.message = this.offer.message ? this.offer.message.replace(/[\n\r\t]/g, '<br/>') : undefined;


      // check case not any item => show offer page
      if (!response.data.offer.items || response.data.offer.items == null) {
        this.noOffer = true;
        this.entries.length = 0;
        this.storage.resetCartStorage(this.farmSlug);
        // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
        //   {queryParams: {farmSlug}});
        return;
      }

      let items: any = [];
       items = Object.values(response.data.offer.items).sort((a: OfferItem, b: OfferItem) => {
        const nameSort = a.name.localeCompare(b.name);
        if (nameSort === 0) {
          return a.itemDetailId - b.itemDetailId;
        } else {
          return nameSort;
        }
      });

      this.totalItem = +response.data.offer.totalItems;
      // this.items = this.items.concat(item);
      var tempPriority = 100;
      var tempCategory = {};
      items.forEach((item, index) => {
        if(item.itemCategoryPriority == null || item.itemCategoryPriority == undefined){
          if(tempCategory[item.itemCategoryName]){
            item.itemCategoryPriority = tempCategory[item.itemCategoryName];
          }else{
            tempCategory[item.itemCategoryName] = tempPriority;
            item.itemCategoryPriority = tempPriority;
            tempPriority++;
          }

        }
        item.sold = +item.sold || 0;
        item.limit = item.limit || 999;
        item.available = +item.available || 999;
        item.soldOut = ((item.sold >= item.limit) || (item.available <= 0)) ? true : false;

        if(this.categories[item.itemCategoryPriority]){
          this.categories[item.itemCategoryPriority].push(item);
        }else{
          this.categories[item.itemCategoryPriority] = [item];
        }
      });


    }, (error: HttpErrorResponse) => {

    }, () => {
      this.isFinishedLoadingOfferData = true;
    });
  }

  onUp(ev) {
    console.log('scrolled up!', ev);

  }

  onScrollDown (ev) {
    console.log('scrolled down!!', ev);
    const totalpage = +(this.totalItem / 40);
    if (this.page < Math.ceil(totalpage)) {
      this.page++;
      this.loadItemStore(this.farmSlug);
    }
  }

  calculateDisplay(farmSlug) {
    this.deliveryHeader = `Delivery date ${new Date(this.offer.window.delivery).toLocaleDateString([], CONSTANTS.DATE_FORMATS.abbreviated)}`;
    this.showNextOrderWindow();
    this.updateOfferStatus();
    if (this.statusIntervalId) {
      window.clearInterval(this.statusIntervalId);
    }
    this.statusIntervalId = window.setInterval(() => {
      this.updateOfferStatus();
    }, 5000);

    this.isClosed = this.isClose();
    this.isListView = this.session.getOfferViewStyleFromCache();
    if (this.isMobile()) {
      this.isListView = false;
    }
    if (this.isClose()) {
      this.noOffer = true;
      this.entries.length = 0;
      this.storage.resetCartStorage(this.farmSlug);
      // this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
      //   {queryParams: {farmSlug}});
    }
  }

  isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  showNextOrderWindow() {

    const unixTimestamp = this.offer.window.open;
    const dateObject = new Date(unixTimestamp);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };

    const date = dateObject.toLocaleString("en-US", { weekday: "long" })
    const month = dateObject.toLocaleString("en-US", { month: "long" })
    const day = dateObject.toLocaleString("en-US", { day: "numeric" })
    const hour = dateObject.toLocaleString("en-US", { hour: "numeric" })
    const minute = dateObject.toLocaleString("en-US", { minute: "numeric" })
    var time = dateObject.toLocaleTimeString([], options);

    this.nextOrderWindow = `${date}, ${month} ${day} ${time}`;
  }

  isClose() {
    const now = (new Date()).getTime();
    if (now < this.offer.window.open || now >= this.offer.window.close) {
      return true;
    } else {
      return false;
    }
  }

  getStatus(): string {
    const now = moment();
    let duration;
    let prefix = '';
    let suffix = '';
    if (this.isClose()) {
      duration = moment.duration(moment(this.offer.window.open).diff(now));
      prefix = 'Opening in ';
    } else {
      duration = moment.duration(moment(this.offer.window.close).diff(now));
      suffix = ' remaining';
    }
    if (duration.as('days') >= 2) {
      return `${prefix}${Math.floor(duration.as('days'))} days${suffix}`;
    } else if (duration.as('days') >= 1) {
      return `${prefix}1 day${suffix}`;
    } else if (duration.as('hours') >= 2) {
      return `${prefix}${Math.floor(duration.as('hours'))} hours${suffix}`;
    } else if (duration.as('hours') >= 1) {
      return `${prefix}1 hour${suffix}`;
    } else if (duration.as('minutes') >= 5) {
      return `${prefix}<1 hour${suffix}`;
    } else if (duration.as('seconds') >= 5) {
      return `${prefix}<5 minutes${suffix}`;
    } else {
      return this.isClose() ? 'Opening...' : 'Closing...';
    }
  }

  updateOfferStatus() {
    const isClosed = this.isClose();
    if (isClosed) {
      this.statusHeader = CONSTANTS.OFFER_STATUS.CLOSED;
      this.statusClass = 'closed';
      this.timeRemaining = this.getStatus();

    } else {
      this.statusHeader = CONSTANTS.OFFER_STATUS.OPEN;
      this.statusClass = 'open';
      this.timeRemaining = this.getStatus();
    }
  }

  getCartLink() {
    return `/${CONSTANTS.ROUTES.CART_PUBLIC(this.farmSlug)}`;
  }

  getCartStatus() {
    return `Cart (${this.entries.length})`;
  }

  getSubtotal() {
    const total = this.entries.reduce((tempTotal, cartEntry, index, items) => {
      return tempTotal + (cartEntry.quantity * cartEntry.item.price);
    }, 0);
    return total;
  }

  addItem(item, quantity) {
    const data: OnlineStoreCartEntry = new OnlineStoreCartEntry(item,
      quantity, true, false, item.sold || 0 + quantity);
    if (this.entries.length === 0) {
      this.onlineStoreCartService.addItem(data);
    } else {
      const index = this.entries.findIndex(x => x.item.id === data.item.id);
      if (index === -1) {
        this.onlineStoreCartService.addItem(data);
      } else if (index !== -1) {
        if (this.entries[index].item.available < (this.entries[index].quantity + quantity)) {
          this.toast.open({
            text: `You cannot put ${this.entries[index].quantity + quantity} ${this.entries[index].item.name} in your cart. There are only ${this.entries[index].item.available} available.`,
            actions: [{
              label: this.labels.btnDismiss,
              callback: () => { }
            }]
          });
          return false;
        }
        this.onlineStoreCartService.updateItemWithAddedQuantity(item.id, {
          quantity, price: item.price, imageUrl: item.imageUrl, available: item.available
        });
      }
    }

    this.storage.setCartList(this.entries, this.farmSlug);
    return true;
  }

  private getCurrencyCode(value){
    return this.pipe.transform(value,(this.farmData.currencyCode === 'CAD' ? 'Can$' : this.farmData.currencyCode),'symbol','0.2-2');
  }

  addToCart(item, quantity) {
    if(!this.isReadonly) {
      var _quantity = Number(quantity) ? quantity : 1;
      // if (this.layout.isSmall()) {
      //   this.dialog.open(AddItemComponent, {
      //     data: {
      //       item,
      //       isClosed: this.isClosed
      //     }
      //   });
      // } else {
      // const quantity = Number(qty);
      // if (qty > 0) {
        item.quantity = '';
        if (this.addItem(item, _quantity)) {
          let subTotal = this.getSubtotal();
          let isShowMinimum = this.offer['orderamountminimum'] && subTotal && subTotal < this.offer['orderamountminimum'];
          let mininumMessage = isShowMinimum ? `Minimum ${this.getCurrencyCode(this.offer['orderamountminimum'])} is required to check out` : '';
          this.toast.openItemAddedToast(item, _quantity, mininumMessage);
        }
      // }
      // }
    }
  }

  getIsListView(): boolean {
    return this.isListView;
  }

  setIsListView(isListView: boolean): void {
    this.isListView = isListView;
    this.session.saveOfferViewStyleToCache(isListView);
  }
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from './services/layout.service';
import { HttpService } from './services/http.service';
import { NavigationEnd, Router } from '@angular/router';
declare var os: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
    title = 'farmlisting';

    isLoading = false;
    osPlatform: string = this.detechOs();
    browser: string = this.detechBrowser();

    userAgent = window.navigator.userAgent;
    public isUnsubscribe: boolean = false;
    constructor(private layout: LayoutService, private httpService: HttpService, private changeDetector: ChangeDetectorRef,
        private router: Router) {
    }

    ngOnInit() {
        this.httpService.getIsLoading().subscribe((isLoading) => {
            setTimeout(() => {
                this.isLoading = isLoading;
                this.changeDetector.detectChanges();
            });
        });
        this.router.events.subscribe((state)=>{
            if(state instanceof NavigationEnd)
            this.isUnsubscribe = this.router.url.indexOf("unsubscribe") != -1;
        })
        
    }

    getClassName() {
        return `fl-main ${this.layout.getLayoutClass()}`;
    }

    private detechOs() {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (macosPlatforms.indexOf(platform) !== -1) {
            return 'mac';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            return 'windows';
        } else if (/Android/.test(userAgent)) {
            return 'android';
        } else if (!os && /Linux/.test(platform)) {
            return 'linux';
        }
    }

    private detechBrowser() {
        const userAgent = window.navigator.userAgent;
        const browsers = {
            edge: this.osPlatform == 'ios' ? /EdgiOS/ :/EdgA/i,
            chrome: this.osPlatform == 'ios' ? /CriOS/i : /chrome/i,
            firefox: this.osPlatform == 'ios' ? /FxiOS/i : /firefox/i,
            ie: /internet explorer/i,
            safari: /safari/i,
        };
        for (const key in browsers) {
            if (browsers[key].test(userAgent)) {
                return key;
            }
        }
    }
}

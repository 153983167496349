import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlPipe } from './pipes/html.pipe';
import { SquarePaymentComponent } from './components/square-payment/square-payment.component';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';

const compo = [
  HtmlPipe,
  SquarePaymentComponent,
  StripePaymentComponent
]
@NgModule({
  declarations: [
    ...compo
  ],
  exports: [
    ...compo  
  ],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }

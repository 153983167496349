import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CONSTANTS } from '../model/enums';
import { TendResponse } from '../model/tend-response';
import { Moment } from 'moment';
import * as moment from 'moment';
import { LogService } from '../shared/components/logger/log.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    loadingSubject: BehaviorSubject<boolean>;
    public isLoadingIndication: boolean = true;
    public forceIndication: boolean = false;
    private logService: LogService = new LogService(this.http);
    constructor(private http: HttpClient, private injector: Injector) {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
    }

    private logTime(startMoment: Moment, url: string, method: string) {
        const requestDuration = moment().diff(startMoment, 'milliseconds');

        this.logService.logHttpInfo(`HTTP ${method}`, requestDuration, url);
    }

    public getIsLoading(): BehaviorSubject<boolean> {
        return this.loadingSubject;
    }

    get<T>(route: string, params?: HttpParams | {
        [param: string]: string | string[];
    }, successCode?: string): Observable<T> {
        this.loadingSubject.next(true);
        

        return new Observable<T>((observer) => {
            const requestBeginTime = moment();
            this.logTime(requestBeginTime, `${route}`, 'GET'),
            this.http.get<TendResponse<T>>(CONSTANTS.API_ROOT + route, {
                observe: 'response',
                params
            }).subscribe((response) => {
                if(successCode != undefined) {
                    if(response.body.meta.code == successCode) {
                        observer.next(response.body.data);
                        observer.complete();
                    }
                    else {
                        observer.error(response.body.meta);
                    }
                    return;
                }
                observer.next(response.body.data);
                observer.complete();
            }, (error) => {
                observer.error(error);
            }).add(() => {
                if(!this.forceIndication)
                this.loadingSubject.next(false);
            });
        });
    }

    // service get full data from response server, include: meta and data
    getfull<T>(route: string, params?: HttpParams | {
        [param: string]: string | string[];
    }): Observable<T> {
        this.loadingSubject.next(true);
        return new Observable<T>((observer) => {
            const requestBeginTime = moment();
            this.logTime(requestBeginTime, `${route}`, 'GET'),
            this.http.get<T>(CONSTANTS.API_ROOT + route, {
                observe: 'response',
                params
            }).subscribe((response) => {
                observer.next(response.body);
                observer.complete();
            }, (error) => {
                observer.error(error);
            }).add(() => {
                if(!this.forceIndication)
                this.loadingSubject.next(false);
            });
        });
    }


    post<T, V>(route: string, data: T): Observable<V> {
        if(route == CONSTANTS.API_ENDPOINT.LOG_PAYMENT)
        {
            this.isLoadingIndication = false; 
        }
        else{
            this.isLoadingIndication = true; 
        }
        if(this.isLoadingIndication)
            this.loadingSubject.next(true);
        return new Observable<V>((observer) => {
            const requestBeginTime = moment();
            this.logTime(requestBeginTime, `${route}`, 'POST'),
            this.http.post<TendResponse<V>>(CONSTANTS.API_ROOT + route, data, {
                observe: 'response'
            }).subscribe((response) => {
                if (response.body.meta.code.endsWith('_f')) {
                    observer.error(response);
                } else {
                    observer.next(response.body.data);
                }
                observer.complete();
            }, (error) => {
                observer.error(error.error.data);
            }).add(() => {
                if(!this.forceIndication)
                this.loadingSubject.next(false);
            });
        });
    }

    postResponse(route: string, data) {
        this.loadingSubject.next(true);
        return new Observable((observer) => {
            this.http.post(CONSTANTS.API_ROOT + route, data, {
                observe: 'response'
            }).subscribe((response) => {
                if (response.body['meta'].code.endsWith('_f')) {
                    observer.error(response);
                } else {
                    observer.next(response.body);
                }
                observer.complete();
            }, (error) => {
                observer.error(error.error.data);
            }).add(() => {
                if(!this.forceIndication)
                this.loadingSubject.next(false);
            });
        });
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private http: HttpService) { }

  unsubscribeService(farmToken: string, rid: string){
    return this.http.post(`farmlisting/private/store/unsubscribe?token=${farmToken}&rid=${rid}`,
    {
      farmToken
    })
  }
}

export interface IFarm {
    id: number;
    name: string;
    slug: string;
    email: string;
    phone: string;
    website: string;
    logoUrl: string;
    currencyCode: string;
    timeZoneId: string;
    about: string;
    storyImageUrls: string[];
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    pickupLocations: IPickupLocation[];
    paymentMethods: IPaymentMethod[];
}

export interface IPickupLocation {
    id: number;
    name: string;
    price: number;
}

export interface IPaymentMethod {
    id: number;
    name: string;
    message: string;
}

export class Farm implements IFarm {
    id: number;
    name: string;
    slug: string;
    email: string;
    phone: string;
    website: string;
    logoUrl: string;
    currencyCode: string;
    timeZoneId: string;
    textcolor?: string;
    backgoundcolor?: string;
    about: string;
    storyImageUrls: string[];
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    pickupLocations: PickupLocation[];
    paymentMethods: PaymentMethod[];

    constructor(farm: IFarm) {
        Object.assign(this, farm);
    }
}

export class PickupLocation implements IPickupLocation {
    id: number;
    name: string;
    price: number;

    constructor(location: IPickupLocation) {
        this.id = location.id;
        this.name = location.name;
        this.price = location.price;
    }
}

export class PaymentMethod implements IPaymentMethod {
    id: number;
    name: string;
    message:  string;

    constructor(method: IPaymentMethod) {
        this.id = method.id;
        this.name = method.name;
        this.message = method.message;
    }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SQUARE_APPLICATION_ID:"sandbox-sq0idb-0MmMcs-AhYvsDqAMlaa3dA",
  // STRIPE_APPLICATION_ID:"ca_N9F8r2evMfVn2gvZP6iIpBQ8zBthZE10",
  // STRIPE_PUBLIC_KEY: "pk_test_51MpXAtLOJ74QS1ILky3HaixPpcRwh6rpgHhwuCsct8uYHb9Ql6NKfAFeTgYuLF4f1Q45AfXG1NqKV4n8QoRwadUk00lyXGlyZM",
  LOCATION_ID: "F89PVK85QJ1N1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

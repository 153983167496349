import { OfferItem, IOfferItem } from './offer';

export class CartEntry {

    constructor(
        public item: OfferItem,
        public quantity: number,
        public exists: boolean = true,
        public isServerSoldOut: boolean = false
    ) { }

    add(quantity: number) {
        this.quantity += quantity;
    }

    getIsValid() {
        return this.exists && !this.getIsOversold() && !this.isServerSoldOut;
    }

    getIsDisabled() {
        return !this.exists || this.isServerSoldOut;
    }

    getIsOversold(): boolean {
        return this.item.isLimitSet() && this.getAvailable() < 0;
    }

    getAvailable(): number {
        return this.item.limit - this.item.sold;
    }
}

export interface ICartEntry {
    quantity: number;
    exists: boolean;
    isServerSoldOut: boolean;
}

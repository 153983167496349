import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CONSTANTS } from 'src/app/model/enums';
import { IPaymentLog } from 'src/app/model/requests/payment-log.model';
import { LoggerService } from 'src/app/services/logger.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-square-payment',
  templateUrl: './square-payment.component.html',
  styleUrls: ['./square-payment.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SquarePaymentComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() feature: "Online Store" | "Offer sheet" | "Payment preferences";
  @Input() email: string;
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  @Output() nonceChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() purchase: BehaviorSubject<any>;
  @Input() type: 'card' | 'giftcard';
  @Input() isValidateCard: boolean;
  private locationId: string = environment.LOCATION_ID;
  private appId: string = environment.SQUARE_APPLICATION_ID;
  private card: any;
  private gifCard: any;
  private payments: any;
  private farmSlug: string;
  private checkFocusCard: boolean = null;
  private checkFocusGifCard: boolean = null;
  constructor(private route: ActivatedRoute, private logSvr: LoggerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(this.isValidateCard) {
        if(this.type === 'card') {
          this.handlePaymentMethodSubmission();
        } else {
          this.handleGiftCardMethodSubmission();
        }
      }
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
    });
    if (this.purchase) {
      this.purchase.subscribe(rec => {
        this.handlePaymentMethodSubmission();
      })
    }
  }

  async ngAfterViewInit() {
    if (!window["Square"]) {
      throw new Error('Square.js failed to load properly');
    }

    try {
      this.payments = window["Square"].payments(this.appId, this.locationId);
    } catch {
      return;
    }

    try {
      if (this.type == 'card') {
        this.card = await this.initializeCard(this.payments);
        // check remove class when outfocus zip
        this.card.addEventListener("focusClassRemoved", (event) => {
          this.checkFocusCard = false;
          this.handlePaymentMethodSubmission();
        }); 

        this.card.addEventListener("focusClassAdded", (event) => {
          this.checkFocusCard = true;
        }); 

        this.card.addEventListener("errorClassAdded", (event) => {
          // this.handleEventError(event)
          if (event.type == "errorClassAdded") {
            this.nonceChange.emit(null);
          }
        })
        this.card.addEventListener("errorClassRemoved", (event) => {
          this.checkFocusCard = false;
          this.handlePaymentMethodSubmission();
        })
      }
      if (this.type == 'giftcard') {
        this.gifCard = await this.initializeGiftCard(this.payments);
        this.gifCard.addEventListener("focusClassRemoved", (event) => {
          const { field } = event.detail;
          this.checkFocusGifCard = false;
          if (field === 'giftCardNumber') {
            this.handleGiftCardMethodSubmission();
          }
        });

        this.gifCard.addEventListener("focusClassAdded", (event) => {
          this.checkFocusGifCard = true;
        }); 
      }
    } catch (e) {
      // this.handleEventError(e);
      return;
    }
  }

  async initializeGiftCard(payments) {
    const giftCard = await payments.giftCard();
    await giftCard.attach(`#idGiftCard`);

    return giftCard;
  }

  async initializeCard(payments) {
    const darkModeCardStyle = {
      '.input-container.is-focus': {
        'borderColor': '#bfdeff',
      },
      input: {
        color: '#495057',
        fontFamily: 'sans-serif'
      }
    };

    const card = await payments.card({
      style: darkModeCardStyle,
    });
    await card.attach(`#card-container`);

    return card;
  }

  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      this.handleEventError(JSON.stringify(tokenResult.errors));
      // throw new Error(
      //   `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
      // );
    }
  }

  // status is either SUCCESS or FAILURE;
  displayPaymentResults(status, msg) {
    const statusContainer = document.getElementsByClassName(
      'sq-card-message'
    )[0];
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('sq-card-message-error');
    } else {
      statusContainer.classList.add('sq-card-message-error');

    }
    statusContainer.innerHTML = msg;
    statusContainer["style"].visibility = 'visible';
  }


  async handlePaymentMethodSubmission() {
    try {
      // disable the submit button as we await tokenization and make a payment request.
      const token = await this.tokenize(this.card);

      this.nonceChange.emit(token);
    } catch (e) {
      // this.handleEventError(e.message);
    }
  }

  async handleGiftCardMethodSubmission() {
    try {
      // disable the submit button as we await tokenization and make a payment request.
      const token = await this.tokenize(this.gifCard);

      this.nonceChange.emit(token);
    } catch (e) {
      this.nonceChange.emit(null);
      this.handleEventError(e);
      if (e.errors) {

        const { field, message } = e.errors[0];
        if (field == "cardNumber") {
          this.displayPaymentResults('FAILURE', "Enter a valid card number.");
        }
        else {
          this.displayPaymentResults('FAILURE', message);
        }
        this.error.emit(message);
      }

    }
  }

  private handleEventError(e, type?) {
    let msg: string = "";
    if (Array.isArray(e)) {
      e.map(f => {
        msg += msg ? ";" + f.message : f.message;
      })
    }
    else {
      msg = e;  
    };
    if(typeof(msg) == "object"){
      try {
        e.errors.map(f => {
          msg += msg ? ";" + f.message : f.message;
        })
      } catch (error) {
        
      }
    }
    let errVm: IPaymentLog = {
      component: this.feature,
      email: this.email,
      farmSlug: this.farmSlug,
      feature: 'Add card',
      info: msg,
      source: 'Square'
    };
    if(this.feature == "Offer sheet"){
      errVm.token = this.farmSlug;
      delete errVm.farmSlug;
    }
    if(this.checkFocusCard == false || this.checkFocusGifCard == false){
      this.logSvr.logErrorCard(errVm).subscribe(rec => rec);
    }
  }
}
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CONSTANTS } from '../model/enums';
import { CartService } from '../services/cart.service';
import { DataStorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class FarmGuard implements CanActivate {
  constructor(
    private storage: DataStorageService,
    private cart: CartService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const farmSlug = next.params[CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG];
    const farm = this.storage.getFarm(farmSlug) || [];
    const isPOS = window.location.pathname.startsWith("/offers/");
    const cartList = this.storage.getCartList(farmSlug) || []
    
    if (farm && Object.keys(farm).length !== 0 && cartList.length > 0) {
      return true;
    } else {
      
      if(isPOS) {
        this.router.navigate([CONSTANTS.ROUTES.PRIVATE_STORE(farmSlug)]);
      } else {
        this.router.navigate([CONSTANTS.ROUTES.STORE(farmSlug)]);
      }
    }
  }

}

import { StripePaymentComponent } from './../../../shared/components/stripe-payment/stripe-payment.component';
import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';
import { BUTTON_COLOR_DEFAULT, CONSTANTS } from 'src/app/model/enums';
import { LayoutService } from 'src/app/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { Offer, OfferItem } from 'src/app/model/offer';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { PaymentService } from 'src/app/services/payment.service';
import { Farm } from 'src/app/model/farm';
import { DataStorageService } from 'src/app/services/storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnlineStoreCartService } from 'src/app/services/online-store-cart.service';
import { ConfirmModalContent } from '../../confirm/confirm.modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationStrategy } from '@angular/common';
import { Stripe } from '@stripe/stripe-js';
import { LogService } from 'src/app/shared/components/logger/log.service';

declare var SqPaymentForm: any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class StoreBillingComponent implements OnInit, OnDestroy {
  @ViewChild ('stripeCard', {static: false}) stripeComponent:StripePaymentComponent;
  labels = {
    btnAdd: 'Add',
    lblQuantity: 'Quantity',
    lblLogout: 'Log out',
    lblNotStarted: 'This offer has not started',
    placeholderEnterAmount: 'Enter amount',
    lblSubtotal: 'Subtotal',
    lblTax: 'Tax',
    lblShipping: 'Delivery',
    lblTotal: 'Total',
    lblGift: 'Gift Card',
    lblBalance: 'Balance'
  };
  listMethod: any = [{ key: 'gift_card', value: 'Gift Card' }, { key: 'credit_card', value: 'Credit Card' }];
  isLoading: false;
  CONSTANTS = CONSTANTS;
  COLOR_DEFAULT_BACKGROUND = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_BACKGROUND;
  COLOR_DEFAULT_TEXT = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_TEXT;

  farmSlug: string;
  form: FormGroup;
  isProcessing: any;

  farm: Farm;
  cartEntries: any = [];
  cartInfo: any;
  orderid: any;
  id: any;
  order: any;
  pickupLocation: any;
  paymentMethod: any;
  farmPublishAbleKey: any;
  paymentSource: any;
  stripeValidation = true;

  offersheetId: number;
  offer: Offer;
  deliveryHeader: string;
  statusHeader: string;
  statusClass: string;
  timeRemaining: string;
  message: string;
  items: OfferItem[];
  isClosed = true;
  errorLog = {};
  giftErrorLog = {};
  listMaster: any = {
    country: [],
    state: []
  };
  flagRemain: any = false;
  balance = 0;
  gift_amount = 0;
  previousState: any = true;
  isValidateCard = false;
  creditOption: string[] = ['SQUARE', 'STRIPE'];
  public statusIntervalId: number;
  public logoutSubscription: Subscription;
  paymentForm: any;
  giftPaymentForm: any;
  otherPaymentForm: any;
  private subscription = new Subscription();
  private nonceCard: string;
  private nonceGiftCard: string;
  private statusSubmit = null;
  private guardToSubmit :BehaviorSubject<boolean> = new BehaviorSubject(false);
  private stripePromise: Promise<Stripe | null>;
  constructor(public cart: CartService, public session: SessionService, private storage: DataStorageService,
    public layout: LayoutService, private toast: ToastService, private router: Router,
    private route: ActivatedRoute, public paymentService: PaymentService, private fb: FormBuilder,
    private deviceService: DeviceDetectorService, private onlineStoreCartService: OnlineStoreCartService,
    private modalService: NgbModal, private location: LocationStrategy,
    private LogSvr: LogService) {
    history.pushState(null, document.URL, window.location.href);
  }

  private async loadStripe(publicKey: string) {
    const stripe = await import('@stripe/stripe-js');
    return stripe.loadStripe(publicKey);
  }
  public stripe: any;
  async ngOnInit() {
    // if(this.previousState) {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: [null],
      postal: ['', [Validators.required]],
      billingaddress2: [''],
      billingcountrycode: [null, Validators.required],
      billingcountryname: [null],
      shippingsameasbilling: false,
      // postal:['', [Validators.required]]
      // note:['', []]

      firstName1: ['', [Validators.required]],
      lastName1: ['', [Validators.required]],
      email1: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone1: ['', [Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]],
      address1: ['', [Validators.required]],
      city1: ['', [Validators.required]],
      state1: [null],
      postal1: ['', [Validators.required]],
      shippingaddress2: [''],
      shippingcountrycode: [null, Validators.required],
      shippingcountryname: [null],
      method: ['credit_card']
    });
    //   this.form.get('shippingsameasbilling').valueChanges.subscribe(shippingsameasbilling => {
    //     if (shippingsameasbilling) {
    //         this.form.patchValue({
    //             "firstName1":this.form.get('firstName').value,
    //             "lastName1":this.form.get('lastName').value,
    //             "email1":this.form.get('email').value,
    //             "phone1":this.form.get('phone1').value,
    //             "address1":this.form.get('address').value,
    //             "city1":this.form.get('city').value,
    //             "state1":this.form.get('state').value
    //         });
    //         // this.form.get('state').reset();
    //         this.form.get('firstName1').disable();
    //         this.form.get('lastName1').disable();
    //         this.form.get('email1').disable();
    //         this.form.get('address1').disable();
    //         this.form.get('city1').disable();
    //         this.form.get('state1').disable();
    //     }
    //     else {
    //         this.form.get('firstName1').enable();
    //         this.form.get('lastName1').enable();
    //         this.form.get('email1').enable();
    //         this.form.get('address1').enable();
    //         this.form.get('city1').enable();
    //         this.form.get('state1').enable();
    //     }
    // });
    this.route.paramMap.subscribe((params) => {
      this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
      const cartEntries = this.storage.getCartList(this.farmSlug) || [];
      this.onlineStoreCartService.initEntries(cartEntries);
      const farm = this.storage.getFarm(this.farmSlug) || [];
      this.farm = farm;
      this.farmPublishAbleKey = farm.publishAbleKey;
      this.paymentSource = farm.paymentSource;
      this.cartInfo = this.storage.getTotal(this.farmSlug) || [];
      this.orderid = this.cartInfo.orderid;

      this.order = this.storage.getOrder(this.farmSlug) || {};
      this.pickupLocation = this.storage.getpickupId(this.farmSlug) || {};
      if (this.pickupLocation && !this.pickupLocation.isdefault) {
        this.form.get('firstName1').disable();
        this.form.get('lastName1').disable();
        this.form.get('email1').disable();
        this.form.get('phone1').disable();
        this.form.get('address1').disable();
        this.form.get('city1').disable();
        this.form.get('postal1').disable();
        this.form.get('shippingaddress2').disable();
        this.form.get('shippingcountrycode').disable();
      }
      this.paymentMethod = this.storage.getPaymentID(this.farmSlug) || {};
      if (this.paymentMethod['id']) { this.changeMethod(this.paymentMethod['id']); }

      if (this.orderid !== this.cartInfo.orderid) {
        console.log('Error not found cart!!!');
      }

      const billingInfo = this.storage.getBillingInfo(this.farmSlug) || {};
      this.form.patchValue({
        "firstName": billingInfo.billingfirstname,
        "lastName": billingInfo.billinglastname,
        "email": billingInfo.billingemail,
        "address": billingInfo.billingaddress,
        "city": billingInfo.billingcity,
        "state": billingInfo.billingstate || null,
        "phone": billingInfo.billingphone,
        "postal": billingInfo.billingpostalcode,
        "billingaddress2": billingInfo.billingaddress2,
        "billingcountrycode": billingInfo.billingcountrycode,
        "billingcountryname": billingInfo.billingcountryname,

        "shippingsameasbilling": billingInfo.shippingsameasbilling,

        "firstName1": billingInfo.shippingfirstname,
        "lastName1": billingInfo.shippinglastname,
        "email1": billingInfo.shippingemail,
        "address1": billingInfo.shippingaddress,
        "city1": billingInfo.shippingcity,
        "state1": billingInfo.shippingstate || null,
        "phone1": billingInfo.shippingphone,
        "postal1": billingInfo.shippingpostalcode,
        "shippingaddress2": billingInfo.shippingaddress2,
        "shippingcountrycode": billingInfo.shippingcountrycode,
        "shippingcountryname": billingInfo.shippingcountryname
      });

      if (CONSTANTS.COUNTRY_HAVE_STATE.indexOf(billingInfo.billingcountrycode) >= 0) {
        this.form.get('state').setValidators([Validators.required]);
        this.paymentService.getState().subscribe(res => {
          switch (billingInfo.billingcountrycode) {
            case 'US': this.listMaster['stateBilling'] = res[1]['states']; break;
            case 'CA': this.listMaster['stateBilling'] = res[0]['states']; break;
            case 'AU': this.listMaster['stateBilling'] = CONSTANTS.AU_STATE; break;
          }
        }, (err) => {
          console.log(err);
        });
      }

      if (CONSTANTS.COUNTRY_HAVE_STATE.indexOf(billingInfo.shippingcountrycode) >= 0) {
        this.form.get('state1').setValidators([Validators.required]);
        this.paymentService.getState().subscribe(res => {
          switch (billingInfo.shippingcountrycode) {
            case 'US': this.listMaster['stateDelivery'] = res[1]['states']; break;
            case 'CA': this.listMaster['stateDelivery'] = res[0]['states']; break;
            case 'AU': this.listMaster['stateDelivery'] = CONSTANTS.AU_STATE; break;
          }
        }, (err) => {
          console.log(err);
        });
      }
    });

    this.subscription.add(
      this.onlineStoreCartService.getEntriesSubscription()
        .subscribe(entries => {
          this.cartEntries = [...entries];
        })
    );
    if(this.paymentMethod.id && this.paymentMethod.id === 3) {
      this.paymentService.stripeValidation(this.farm.id).subscribe(res => {
        if(!res) {
          this.stripeValidation = false;
          let windowClosedConfig = {
            text: "The stripe account for this rancher has been cut off from TEND. For help, please get in touch with the farm's owner.",
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true,
            duration: 5000,
          };
          this.toast.open(windowClosedConfig);
        }
      }, (err) => {
        console.log(err);
      })
    }
    if(this.farmPublishAbleKey) {
      this.stripePromise = this.loadStripe(this.farmPublishAbleKey);
    }
    this.stripe = await this.stripePromise;
    this.getListCountry();
    // this.createCreditPaymentForm();
    // this.paymentForm.build();

    // if (this.form.value.method === 'credit_card') {
    //   this.createCreditPaymentForm();
    //   this.paymentForm.build();
    // } else {
    //   this.createGiftPaymentForm();
    //   this.giftPaymentForm.build();
    // }
    // }
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    if (event) event.returnValue = false;
    return false;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.previousState) {
      event.preventDefault();
      history.pushState(null, document.URL, window.location.href);
      console.log('Back button pressed');
      // this.previousState = false;

      const modalRef = this.modalService.open(ConfirmModalContent);
      modalRef.componentInstance.message =
        'Transaction has not finished, leaving this page will revert all changes.';
      modalRef.componentInstance.yesButtonText = 'Continue';
      modalRef.componentInstance.noButtonText = 'Cancel';
      modalRef.result.then(
        yes => {
          if (yes) {
            this.router.navigate([CONSTANTS.ROUTES.CART_PUBLIC(this.farmSlug)]);
            return;
          }
        },
        no => {
          history.pushState(null, document.URL, window.location.href);
          return;
          // this.previousState = false;
        }
      );
    }
  }

  changeMethod(item) {
    let key = item == 3 ? 'credit_card' : item;
    this.form.patchValue({ method: key });
    if (item === 'credit_card') {
      // this.createCreditPaymentForm();
      // this.paymentForm.build();
    } else {
      // this.createGiftPaymentForm();
      // this.giftPaymentForm.build();
    }
    if ([ 5, 6 ].includes(key)) {
      this.createOtherPaymentForm();
    }
  }

  getListCountry() {
    this.paymentService.getCountry().subscribe(res => {
      this.listMaster['country'] = res;
    }, (err) => {
      console.log(err);

    });
  }

  changeCountryBilling(event) {
    this.form.patchValue({ billingcountryname: event.countryname });
    this.form.get('state').reset();
    if (CONSTANTS.COUNTRY_HAVE_STATE.indexOf(this.form.value.billingcountrycode) >= 0) {
      this.form.get('state').setValidators([Validators.required]);
      this.form.controls['state'].updateValueAndValidity();
      this.paymentService.getState().subscribe(res => {
        switch (this.form.value.billingcountrycode) {
          case 'US': this.listMaster['stateBilling'] = res[1]['states']; break;
          case 'CA': this.listMaster['stateBilling'] = res[0]['states']; break;
          case 'AU': this.listMaster['stateBilling'] = CONSTANTS.AU_STATE; break;
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.form.patchValue({ state: null });
      this.listMaster['stateBilling'] = [];
      this.form.get('state').clearValidators();
      this.form.get('state').updateValueAndValidity();
    }
  }

  changeCountryDelivery(event) {
    this.form.patchValue({ shippingcountryname: event.countryname });
    this.form.get('state1').reset();
    if (CONSTANTS.COUNTRY_HAVE_STATE.indexOf(this.form.value.shippingcountrycode) >= 0) {
      this.form.get('state1').setValidators([Validators.required]);
      this.form.controls['state1'].updateValueAndValidity();
      this.paymentService.getState().subscribe(res => {
        switch (this.form.value.shippingcountrycode) {
          case 'US': this.listMaster['stateDelivery'] = res[1]['states']; break;
          case 'CA': this.listMaster['stateDelivery'] = res[0]['states']; break;
          case 'AU': this.listMaster['stateDelivery'] = CONSTANTS.AU_STATE; break;
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.form.patchValue({ state1: null });
      this.listMaster['stateDelivery'] = [];
      this.form.get('state1').clearValidators();
      this.form.get('state1').updateValueAndValidity();
    }
  }

  isHaveShipping() {
    return this.pickupLocation.isdefault;
  }

  goBack() {
    var that = this;
    this.storage.setBillingInfo({
      billingfirstname: that.form.get('firstName').value,
      billinglastname: that.form.get('lastName').value,
      billingaddress: that.form.get('address').value,
      billingcity: that.form.get('city').value,
      billingstate: that.form.get('state').value,
      billingemail: that.form.get('email').value,
      billingphone: that.form.get('phone').value,
      billingpostalcode: that.form.get('postal').value,
      billingaddress2: that.form.get('billingaddress2').value,
      billingcountrycode: that.form.get('billingcountrycode').value,
      billingcountryname: that.form.get('billingcountryname').value,
      shippingsameasbilling: that.form.get('shippingsameasbilling').value,

      shippingfirstname: that.form.get('firstName1').value,
      shippinglastname: that.form.get('lastName1').value,
      shippingaddress: that.form.get('address1').value,
      shippingcity: that.form.get('city1').value,
      shippingstate: that.form.get('state1').value,
      shippingemail: that.form.get('email1').value,
      shippingphone: that.form.get('phone1').value,
      shippingpostalcode: that.form.get('postal1').value,
      shippingaddress2: that.form.get('shippingaddress2').value,
      shippingcountrycode: that.form.get('shippingcountrycode').value,
      shippingcountryname: that.form.get('shippingcountryname').value,
    }, this.farmSlug);
    window.history.back();
  }

  createGiftPaymentForm() {
    var that = this;
    // Set the application ID
    var applicationId = environment.SQUARE_APPLICATION_ID;

    // Set the location ID
    // var locationId = "CBASELjav8kAOzgP4SZlbX46e_IgAQ";
    this.giftPaymentForm = new SqPaymentForm({

      // Initialize the payment form elements
      applicationId: applicationId,
      // locationId: locationId,
      inputClass: 'sq-input',
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [{
        fontSize: '14px',
        lineHeight: '32px',
        //boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        color: 'rgba(0,0,0,0.87)',
        padding: '0',
        backgroundColor: '#fff',
      }],



      // Initialize the credit card placeholders
      giftCard: {
        elementId: 'sq-gift-card',
        placeholder: ""
      },

      // SqPaymentForm callback functions
      callbacks: {

        /*
        * callback function: methodsSupported
        * Triggered when: the page is loaded.
        */
        methodsSupported: function (methods) {

        },

        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */
        cardNonceResponseReceived: function (errors, nonce, cardData) {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            // console.log("Encountered errors:");
            that.giftErrorLog = {};
            console.log(errors);

            errors.forEach(function (error) {
              // console.log('  ' + error.message);
              that.giftErrorLog[error.field] = error.message;
              if (error.message == 'Credit card number is not valid') {
                that.giftErrorLog[error.field] = 'Gift card number is not valid';
                error.message = 'Gift card number is not valid';
              }
              let windowClosedConfig = {
                text: error.message,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
            });
            // debugger;

            // errors.forEach(function(error) {
            //     $(paymentInfo[error.field].selector)
            //         .addClass("sq-input--error")
            //         .parent().addClass("hasError")
            //         .find(".error").text(paymentInfo[error.field].message);
            // });

            return;
          }
          if (that.isProcessing) return;
          that.isProcessing = true;

          const data = {
            billingfirstname: that.form.get('firstName').value,
            billinglastname: that.form.get('lastName').value,
            billingaddress: that.form.get('address').value,
            billingcity: that.form.get('city').value,
            billingstate: that.form.get('state').value,
            billingemail: that.form.get('email').value,
            billingphone: that.form.get('phone').value,
            billingpostalcode: that.form.get('postal').value,
            billingaddress2: that.form.get('billingaddress2').value,
            billingcountrycode: that.form.get('billingcountrycode').value,
            billingcountryname: that.form.get('billingcountryname').value,
            // note:that.form.get('note').value

            shippingfirstname: that.form.get('firstName1').value,
            shippinglastname: that.form.get('lastName1').value,
            shippingaddress: that.form.get('address1').value,
            shippingcity: that.form.get('city1').value,
            shippingstate: that.form.get('state1').value,
            shippingemail: that.form.get('email1').value,
            shippingphone: that.form.get('phone1').value,
            shippingpostalcode: that.form.get('postal1').value,
            shippingaddress2: that.form.get('shippingaddress2').value,
            shippingcountrycode: that.form.get('shippingcountrycode').value,
            shippingcountryname: that.form.get('shippingcountryname').value
          }

          const card = "SQUARE_GIFT_CARD";
          that.paymentService.createGiftPayment(nonce, that.farm.id, data, that.orderid, that.order, card, 'SQUARE').subscribe((result) => {

            if (result.meta.code === '45_25_s') {
              that.form.patchValue({ method: 'credit_card' });
              that.flagRemain = true;
              that.gift_amount = +result.data['paymentamountreceived'];
              that.balance = (+result.data['paymentamount'] - +result.data['paymentamountreceived']);

              that.order['orderid'] = result.data['orderid'];
              that.order['id'] = result.data['id'];
              that.isProcessing = false;
              that.createCreditPaymentForm();
              that.paymentForm.build();
            } else {
              that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
              that.storage.resetCartStorage(that.farmSlug);
              that.storage.resetTotalStorage(that.farmSlug);
              that.storage.resetNote(that.farmSlug);
              that.storage.resetpickupId(that.farmSlug);
              that.storage.resetBillingInfo(that.farmSlug);
              that.storage.resetOrder(that.farmSlug);
              that.storage.resetPaymentID(that.farmSlug);
            }


          }, (error) => {
            if (error.body.meta.code === '47_16_f') {
              that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
            }
            that.giftErrorLog = {};
            if (error.body.meta.code === '45_23_f') {
              let windowClosedConfig = {
                text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
            }
            if (error.body.meta.code === '47_30_f') {
              let windowClosedConfig = {
                text: error.body.meta.message,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
              return false;
            }
            if (error.body.meta.code === '47_16_f') {
              that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
            } else {
              let windowClosedConfig = {
                text: `Something went wrong, please try again later or contact us at hello@tend.com. Error Info: ${error.body.meta.code} - ${error.body.meta.message}`,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true
              };
              that.toast.open(windowClosedConfig);
            }
          });

          // Assign the nonce value to the hidden form field
          // document.getElementById('card-nonce').value = nonce;
          //needs to be extracted from the
          // (<HTMLInputElement>document.getElementById('card-nonce')).value = nonce; //casting so .value will work
          // //get this value from the database when the user is logged in
          // (<HTMLInputElement>document.getElementById('sq-id')).value = "CBASEC8F-Phq5_pV7UNi64_kX_4gAQ";

          // // POST the nonce form to the payment processing page
          // (<HTMLFormElement>document.getElementById('nonce-form')).submit();

        },

        /*
        * callback function: unsupportedBrowserDetected
        * Triggered when: the page loads and an unsupported browser is detected
        */
        unsupportedBrowserDetected: function () {
          /* PROVIDE FEEDBACK TO SITE VISITORS */
        },

        /*
        * callback function: inputEventReceived
        * Triggered when: visitors interact with SqPaymentForm iframe elements.
        */
        inputEventReceived: function (inputEvent) {
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'focusClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'cardBrandChanged':
              /* HANDLE AS DESIRED */
              break;
            case 'postalCodeChanged':
              /* HANDLE AS DESIRED */
              break;
          }
        },

        /*
        * callback function: paymentFormLoaded
        * Triggered when: SqPaymentForm is fully loaded
        */
        giftPaymentFormLoaded: function () {
          /* HANDLE AS DESIRED */
        }
      }
    });
  }

  createCreditPaymentForm() {

    var that = this;
    // Set the application ID
    var applicationId = environment.SQUARE_APPLICATION_ID;

    // Set the location ID
    // var locationId = "CBASELjav8kAOzgP4SZlbX46e_IgAQ";
    this.paymentForm = new SqPaymentForm({

      // Initialize the payment form elements
      applicationId: applicationId,
      // locationId: locationId,
      inputClass: 'sq-input',
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [{
        fontSize: '14px',
        lineHeight: '32px',
        //boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        color: 'rgba(0,0,0,0.87)',
        padding: '0',
        backgroundColor: '#fff',
      }],



      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card Number'
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV'
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY'
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Postal Code'
      },

      // SqPaymentForm callback functions
      callbacks: {

        /*
        * callback function: methodsSupported
        * Triggered when: the page is loaded.
        */
        methodsSupported: function (methods) {

        },

        /*
        * callback function: cardNonceResponseReceived
        * Triggered when: SqPaymentForm completes a card nonce request
        */
        cardNonceResponseReceived: function (errors, nonce, cardData) {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            // console.log("Encountered errors:");
            that.errorLog = {};
            console.log(errors);

            errors.forEach(function (error) {
              // console.log('  ' + error.message);
              that.errorLog[error.field] = error.message;
              let windowClosedConfig = {
                text: error.message,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
            });
            // debugger;

            // errors.forEach(function(error) {
            //     $(paymentInfo[error.field].selector)
            //         .addClass("sq-input--error")
            //         .parent().addClass("hasError")
            //         .find(".error").text(paymentInfo[error.field].message);
            // });

            return;
          }
          if (that.isProcessing) return;
          that.isProcessing = true;

          const data = {
            billingfirstname: that.form.get('firstName').value,
            billinglastname: that.form.get('lastName').value,
            billingaddress: that.form.get('address').value,
            billingcity: that.form.get('city').value,
            billingstate: that.form.get('state').value,
            billingemail: that.form.get('email').value,
            billingphone: that.form.get('phone').value,
            billingpostalcode: that.form.get('postal').value,
            billingaddress2: that.form.get('billingaddress2').value,
            billingcountrycode: that.form.get('billingcountrycode').value,
            billingcountryname: that.form.get('billingcountryname').value,
            // note:that.form.get('note').value

            shippingfirstname: that.form.get('firstName1').value,
            shippinglastname: that.form.get('lastName1').value,
            shippingaddress: that.form.get('address1').value,
            shippingcity: that.form.get('city1').value,
            shippingstate: that.form.get('state1').value,
            shippingemail: that.form.get('email1').value,
            shippingphone: that.form.get('phone1').value,
            shippingpostalcode: that.form.get('postal1').value,
            shippingaddress2: that.form.get('shippingaddress2').value,
            shippingcountrycode: that.form.get('shippingcountrycode').value,
            shippingcountryname: that.form.get('shippingcountryname').value
          }

          // BE(a Vinh.Dang) said use same api for gift card and credit card
          const card = "CARD";
          that.paymentService.createGiftPayment(nonce, that.farm.id, data, that.orderid, that.order, card, 'SQUARE').subscribe((result) => {
            that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
            that.storage.resetCartStorage(that.farmSlug);
            that.storage.resetTotalStorage(that.farmSlug);
            that.storage.resetNote(that.farmSlug);
            that.storage.resetpickupId(that.farmSlug);
            that.storage.resetBillingInfo(that.farmSlug);
            that.storage.resetOrder(that.farmSlug);
            that.storage.resetPaymentID(that.farmSlug);
            // Clear cart data
            that.onlineStoreCartService.clearAllEntries();
            that.paymentService.sendOrderMetaData({
              orderid: result.data.id,
              metadata: JSON.stringify({
                deviceInfo: that.deviceService.getDeviceInfo(),
                isMobile: that.deviceService.isMobile(),
                isTablet: that.deviceService.isTablet(),
                isDesktopDevice: that.deviceService.isDesktop()
              })
            }).subscribe(() => { });
          }, (error) => {
            if (error.body.meta.code === '47_16_f') {
              that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
            }
            that.errorLog = {};
            if (error.body.meta.code === '45_23_f') {
              let windowClosedConfig = {
                text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
            }
            if (error.body.meta.code === '47_16_f') {
              that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
            }
            if (error.body.meta.code === '47_30_f') {
              let windowClosedConfig = {
                text: error.body.meta.message,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true,
                closeX: true
              };
              that.toast.open(windowClosedConfig);
            } else {
              let windowClosedConfig = {
                text: `Something went wrong, please try again later or contact us at hello@tend.com`,
                actions: [
                  {
                    label: 'Dismiss',
                    callback: () => {
                    }
                  }
                ],
                isError: true
              };
              that.toast.open(windowClosedConfig);
            }
          });

          // Assign the nonce value to the hidden form field
          // document.getElementById('card-nonce').value = nonce;
          //needs to be extracted from the
          // (<HTMLInputElement>document.getElementById('card-nonce')).value = nonce; //casting so .value will work
          // //get this value from the database when the user is logged in
          // (<HTMLInputElement>document.getElementById('sq-id')).value = "CBASEC8F-Phq5_pV7UNi64_kX_4gAQ";

          // // POST the nonce form to the payment processing page
          // (<HTMLFormElement>document.getElementById('nonce-form')).submit();

        },

        /*
        * callback function: unsupportedBrowserDetected
        * Triggered when: the page loads and an unsupported browser is detected
        */
        unsupportedBrowserDetected: function () {
          /* PROVIDE FEEDBACK TO SITE VISITORS */
        },

        /*
        * callback function: inputEventReceived
        * Triggered when: visitors interact with SqPaymentForm iframe elements.
        */
        inputEventReceived: function (inputEvent) {
          switch (inputEvent.eventType) {
            case 'focusClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'focusClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassAdded':
              /* HANDLE AS DESIRED */
              break;
            case 'errorClassRemoved':
              /* HANDLE AS DESIRED */
              break;
            case 'cardBrandChanged':
              /* HANDLE AS DESIRED */
              break;
            case 'postalCodeChanged':
              /* HANDLE AS DESIRED */
              break;
          }
        },

        /*
        * callback function: paymentFormLoaded
        * Triggered when: SqPaymentForm is fully loaded
        */
        paymentFormLoaded: function () {
          /* HANDLE AS DESIRED */
        }
      }
    });
  }

  createOtherPaymentForm(){
    var that = this;
    this.otherPaymentForm = {
      requestCardNonce: _ => {
        if(that.isProcessing)return;
        that.isProcessing = true;

        let data = {
            billingfirstname:that.form.get('firstName').value,
            billinglastname:that.form.get('lastName').value,
            billingaddress:that.form.get('address').value,
            billingcity:that.form.get('city').value,
            billingstate:that.form.get('state').value,
            billingemail:that.form.get('email').value,
            billingphone:that.form.get('phone').value,
            billingpostalcode:that.form.get('postal').value,
            billingaddress2:that.form.get('billingaddress2').value,
            billingcountrycode:that.form.get('billingcountrycode').value,
            billingcountryname:that.form.get('billingcountryname').value,
            // note:that.form.get('note').value

            shippingfirstname:that.form.get('firstName1').value,
            shippinglastname:that.form.get('lastName1').value,
            shippingaddress:that.form.get('address1').value,
            shippingcity:that.form.get('city1').value,
            shippingstate:that.form.get('state1').value,
            shippingemail:that.form.get('email1').value,
            shippingphone:that.form.get('phone1').value,
            shippingpostalcode:that.form.get('postal1').value,
            shippingaddress2:that.form.get('shippingaddress2').value,
            shippingcountrycode:that.form.get('shippingcountrycode').value,
            shippingcountryname:that.form.get('shippingcountryname').value,
            token: this.farmSlug,
        };
        // Check != Delivery
        if(!that.isHaveShipping()){
          data.shippingfirstname=that.form.get('firstName').value;
          data.shippinglastname=that.form.get('lastName').value;
          data.shippingaddress=that.form.get('address').value;
          data.shippingcity=that.form.get('city').value;
          data.shippingstate=that.form.get('state').value;
          data.shippingemail=that.form.get('email').value;
          data.shippingphone=that.form.get('phone').value;
          data.shippingpostalcode=that.form.get('postal').value;
          data.shippingaddress2=that.form.get('billingaddress2').value;
          data.shippingcountrycode=that.form.get('billingcountrycode').value;
          data.shippingcountryname=that.form.get('shippingcountryname').value;
        }

        that.paymentService.createGiftPayment(null, that.farm.id, data, that.orderid, that.order, null, 'SQUARE').subscribe((result) => {
            if (result.meta.code === '45_25_s') {
              that.form.patchValue({method: 'credit_card'});
              that.flagRemain = true;
              that.gift_amount = +result.data['paymentamountreceived'];
              that.balance = (+result.data['paymentamount'] - +result.data['paymentamountreceived']);

              that.order['orderid'] = result.data['orderid'];
              that.order['id'] = result.data['id'];
              that.isProcessing = false;
              // that.createCreditPaymentForm();
            } else {
              that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
              that.storage.resetCartStorage(that.farmSlug);
              that.storage.resetTotalStorage(that.farmSlug);
              that.storage.resetNote(that.farmSlug);
              that.storage.resetpickupId(that.farmSlug);
              that.storage.resetBillingInfo(that.farmSlug);
              that.storage.resetOrder(that.farmSlug);
              that.storage.resetPaymentID(that.farmSlug);

              that.onlineStoreCartService.clearAllEntries();
              that.paymentService.sendOrderMetaData({
                orderid: result.data.id,
                metadata: JSON.stringify({
                  deviceInfo: that.deviceService.getDeviceInfo(),
                  isMobile: that.deviceService.isMobile(),
                  isTablet: that.deviceService.isTablet(),
                  isDesktopDevice: that.deviceService.isDesktop()
                })
              }).subscribe(() => {});
            }
        }, (error) =>  {
          if (error.body.meta.code === '47_16_f') {
            that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
          }
          that.errorLog = {};
          if (error.body.meta.code === '45_23_f') {
            let windowClosedConfig = {
              text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
              actions: [
                {
                  label: 'Dismiss',
                  callback: () => {
                  }
                }
              ],
              isError: true,
              closeX: true
            };
            that.toast.open(windowClosedConfig);
            return;
          }
          if (error.body.meta.code === '0_7_f'){
            that.statusSubmit = false;
            that.checkDisablePurchase();
            let message = error.body.extendData || `Your card is invalid. Please verify your card information.`;
            if(error.body.extendData && message.indexOf('GENERIC_DECLINE') > -1){
              message = "Your credit card was declined by Square, there seems to be an issue with your card.";
            }
            message = message.replace(';', ',');
            let windowClosedConfig = {
              text: message,
              actions: [
                  {
                      label: 'Dismiss',
                      callback: () => {
                      }
                  }
              ],
              isError: true,
              closeX: true
          };

          that.toast.open(windowClosedConfig);
          return;
          }
          if (error.body.meta.code === '47_30_f') {
            let windowClosedConfig = {
              text: error.body.meta.message,
              actions: [
                {
                  label: 'Dismiss',
                  callback: () => {
                  }
                }
              ],
              isError: true,
              closeX: true
            };
            that.toast.open(windowClosedConfig);
          }
          else {
            let windowClosedConfig = {
              text: `Something went wrong, please try again later or contact us at hello@tend.com`,
              actions: [
                {
                  label: 'Dismiss',
                  callback: () => {
                  }
                }
              ],
              isError: true
            };
            that.toast.open(windowClosedConfig);

          }
        });
      }
    };
  }

  requestCardNonce() {
    // Don't submit the form until SqPaymentForm returns with a nonce
    // event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
  }

  requestGifCardNonce() {
    // Don't submit the form until SqPaymentForm returns with a nonce
    // event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.giftPaymentForm.requestCardNonce();
  }

  requestOtherPayment() {
    this.otherPaymentForm.requestCardNonce();
  }


  onSubmit() {
    //pad data
    this.form.patchValue({ 'firstName': this.form.value.firstName ? this.form.value.firstName.replace(/^\s+|\s+$/g, "") : '' });
    this.form.patchValue({ 'lastName': this.form.value.lastName ? this.form.value.lastName.replace(/^\s+|\s+$/g, "") : '' });
    this.isValidateCard = true;
    if (this.form.value['shippingsameasbilling'] || !(this.isHaveShipping() && !this.flagRemain)) {
      this.form.patchValue({
        "firstName1": this.form.get('firstName').value,
        "lastName1": this.form.get('lastName').value,
        "email1": this.form.get('email').value,
        "phone1": this.form.get('phone').value,
        "address1": this.form.get('address').value,
        "city1": this.form.get('city').value,
        "state1": this.form.get('state').value,
        "postal1": this.form.get('postal').value,
        "shippingaddress2": this.form.get('billingaddress2').value,
        "shippingcountrycode": this.form.get('billingcountrycode').value,
        "shippingcountryname": this.form.get('billingcountryname').value,
      });
    }
    
    if(this.form.value.shippingcountrycode){
      let contryName = this.listMaster.country.find(f=>f.alpha2 == this.form.value.shippingcountrycode).countryname;
      this.form.patchValue({"shippingcountryname":contryName})
    }

    if(this.form.value.shippingcountrycode){
      let contryName = this.listMaster.country.find(f=>f.alpha2 == this.form.value.shippingcountrycode).countryname;
      this.form.patchValue({"shippingcountryname":contryName})
    }

    this.form.patchValue({ 'firstName1': this.form.value.firstName1 ? this.form.value.firstName1.replace(/^\s+|\s+$/g, "") : '' });
    this.form.patchValue({ 'lastName1': this.form.value.lastName1 ? this.form.value.lastName1.replace(/^\s+|\s+$/g, "") : '' });

    if (this.form.valid) {
      if(this.cartInfo && this.cartInfo.total &&
      ((this.form.value.method == 'credit_card' && !this.nonceCard && this.paymentSource !== 'STRIPE') || this.form.value.method == 'gift_card' && !this.nonceGiftCard))
      {
        this.guardToSubmit.next(true)
        return;
      }
      this.statusSubmit = false;
      this.checkDisablePurchase();
      this.LogSvr.logInfo("Check Sold Out::Can Submit billing::" + this.form.value.method, this.farmSlug, "Online Store", this.farm.email);
      const dataBilling = this.getDataBilling();
      if (this.form.value.method == 'credit_card') {
        const result = this.submitBilling(dataBilling);
      } else if (this.form.value.method == 'gift_card') {
        const result = this.submitGiftCardpayment(dataBilling)
      }
      else {
        this.requestOtherPayment();
      }
    }
    else {
      Object.keys(this.form.controls).forEach(field => {  //{2}
        const control = this.form.get(field);
        //{3}
        if (control instanceof FormControl) {             //{4}
          control.markAsTouched({ onlySelf: true });
        }
      });
    }

    if (window.dataLayer) {
      const layer = window.dataLayer 
      if (!layer.find((f: any) => f.email)) {
        window.dataLayer.push({
          "customerEmail": this.form.value.email
        })
        window.clarity('set', 'customerEmail', this.form.value.email)
      }
    }
  }

  getNonceAndSubmitCard(nonce: string) {
    this.nonceCard = nonce;
    this.statusSubmit = null;
    this.checkDisablePurchase();
    // if(this.guardToSubmit.value){
    //   this.onSubmit();
    // }
  }

  getNonceAndSubmitGiftCard(nonce: string) {
    this.nonceGiftCard = nonce;
    this.statusSubmit = null;
    this.checkDisablePurchase();
    // if(this.guardToSubmit.value){
    //   this.onSubmit();
    // }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateOfferStatus() {
    const isClosed = this.offer.isClosed();
    if (isClosed) {
      this.statusHeader = CONSTANTS.OFFER_STATUS.CLOSED;
      this.statusClass = 'closed';
      this.timeRemaining = this.offer.getStatus();

    } else {
      this.statusHeader = CONSTANTS.OFFER_STATUS.OPEN;
      this.statusClass = 'open';
      this.timeRemaining = this.offer.getStatus();
    }
  }

  getCartLink() {
    return `/${CONSTANTS.ROUTES.CART(this.farmSlug)}`;
  }

  getCartStatus() {
    return `Cart (${this.cart.getItemCount()})`;
  }

  private getDataBilling() {
    let data = {
      billingfirstname: this.form.get('firstName').value,
      billinglastname: this.form.get('lastName').value,
      billingaddress: this.form.get('address').value,
      billingcity: this.form.get('city').value,
      billingstate: this.form.get('state').value,
      billingemail: this.form.get('email').value,
      billingphone: this.form.get('phone').value,
      billingpostalcode: this.form.get('postal').value,
      billingaddress2: this.form.get('billingaddress2').value,
      billingcountrycode: this.form.get('billingcountrycode').value,
      billingcountryname: this.form.get('billingcountryname').value,
      shippingfirstname: this.form.get('firstName1').value,
      shippinglastname: this.form.get('lastName1').value,
      shippingaddress: this.form.get('address1').value,
      shippingcity: this.form.get('city1').value,
      shippingstate: this.form.get('state1').value,
      shippingemail: this.form.get('email1').value,
      shippingphone: this.form.get('phone1').value,
      shippingpostalcode: this.form.get('postal1').value,
      shippingaddress2: this.form.get('shippingaddress2').value,
      shippingcountrycode: this.form.get('shippingcountrycode').value,
      shippingcountryname: this.form.get('shippingcountryname').value,
      isSameShippingBilling: this.form.get('shippingsameasbilling').value,
    }
    // Check != Delivery
    if(!this.isHaveShipping()){
      data.shippingfirstname=this.form.get('firstName').value;
      data.shippinglastname=this.form.get('lastName').value;
      data.shippingaddress=this.form.get('address').value;
      data.shippingcity=this.form.get('city').value;
      data.shippingstate=this.form.get('state').value;
      data.shippingemail=this.form.get('email').value;
      data.shippingphone=this.form.get('phone').value;
      data.shippingpostalcode=this.form.get('postal').value;
      data.shippingaddress2=this.form.get('billingaddress2').value;
      data.shippingcountrycode=this.form.get('billingcountrycode').value;
      data.shippingcountryname=this.form.get('shippingcountryname').value;
      if(this.form.get('billingcountrycode').value){
        let contryName = this.listMaster.country.find(f=>f.alpha2 == data.shippingcountrycode).countryname;
        data.shippingcountryname = contryName;
      }
    }
    return data;
  }

  private resetInformation(that) {
    that.storage.resetCartStorage(that.farmSlug);
    that.storage.resetTotalStorage(that.farmSlug);
    that.storage.resetNote(that.farmSlug);
    that.storage.resetpickupId(that.farmSlug);
    that.storage.resetBillingInfo(that.farmSlug);
    that.storage.resetOrder(that.farmSlug);
    that.storage.resetPaymentID(that.farmSlug);
    // Clear cart data
    that.onlineStoreCartService.clearAllEntries();
  }

  private submitBilling(data) : boolean | void{
    let that = this;
    // BE(a Vinh.Dang) said use same api for gift card and credit card
    const card = this.order.paymentamount ? "CARD" : null;
    this.nonceCard = this.order.paymentamount ? this.nonceCard : 'cnon';
    function handlePayment(cardKey: string, cardToken?: any) {
      this.LogSvr.logInfo(`Submit billing::${this.form.value.method}::card nonce::${this.nonceCard}`, this.farmSlug, 'Online Store', this.farm.email)
      that.paymentService.createGiftPayment(cardKey, that.farm.id, data, that.orderid, that.order, card, that.paymentSource, cardToken).subscribe((result) => {
        that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
        that.resetInformation(that);
        this.LogSvr.logInfo(`Submit billing - Send Order MetaData::${JSON.stringify({
          orderid: result.data.id,
          metadata: JSON.stringify({
            deviceInfo: that.deviceService.getDeviceInfo(),
            isMobile: that.deviceService.isMobile(),
            isTablet: that.deviceService.isTablet(),
            isDesktopDevice: that.deviceService.isDesktop()
          })
        })}`, this.farmSlug, 'Online Store', this.farm.email);
        that.paymentService.sendOrderMetaData({
          orderid: result.data.id,
          metadata: JSON.stringify({
            deviceInfo: that.deviceService.getDeviceInfo(),
            isMobile: that.deviceService.isMobile(),
            isTablet: that.deviceService.isTablet(),
            isDesktopDevice: that.deviceService.isDesktop()
          })
        }).subscribe(() => {
          this.LogSvr.logInfo(`Submit billing - Send Order MetaData::SUCCESS`, this.farmSlug, 'Online Store', this.farm.email);
         },(error) => {
          this.LogSvr.logError(`Submit billing - Send Order MetaData::ERROR::${error.message}`, this.farmSlug, 'Online Store', this.farm.email);
         });
      }, (error) => {
        this.LogSvr.logInfo(`Submit billing::ERROR::${error.body.meta.code}::${JSON.stringify(error)}`, this.farmSlug, 'Online Store', this.farm.email);
        if (error.body.meta.code === '47_16_f') {
          that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
        }
        that.errorLog = {};
        if (error.body.meta.code === '45_23_f') {
          let windowClosedConfig = {
            text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true,
            closeX: true
          };
          that.toast.open(windowClosedConfig);
          return;
        }
        if (error.body.meta.code === "0_7_f") {
          that.statusSubmit = false;
          that.checkDisablePurchase();
          let message = error.body.extendData || `Your card is invalid. Please verify your card information.`;
            if(error.body.extendData && message.indexOf('GENERIC_DECLINE') > -1){
              message = "Your credit card was declined by Square, there seems to be an issue with your card.";
            }
            message = message.replace(';', ',');
          let windowClosedConfig = {
            text: message,
            actions: [
              {
                label: "Dismiss",
                callback: () => {},
              },
            ],
            isError: true,
            closeX: true
          };

          that.toast.open(windowClosedConfig);
          return;
        }
        if (error.body.meta.code === '47_30_f') {
          let windowClosedConfig = {
            text: error.body.meta.message,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true,
            closeX: true
          };
          that.toast.open(windowClosedConfig);
          return;
        }
        else {
          let windowClosedConfig = {
            text: `Something went wrong, please try again later or contact us at hello@tend.com. Error Info: ${error.body.meta.code} - ${error.body.meta.message}`,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true
          };
          that.toast.open(windowClosedConfig);
  
        }
      });
    }
    if(that.paymentSource === 'STRIPE') {
      this.stripeComponent.payWithCard().subscribe(res => {
        if(res !== null) {
          if(res && res.card) {
            handlePayment(res.card.id, res.id);
          } else {
            let windowClosedConfig = {
              text: res.message,
              actions: [
                {
                  label: 'Dismiss',
                  callback: () => {
                  }
                }
              ],
              isError: true,
              closeX: true
            };
            that.toast.open(windowClosedConfig);
          }
        }
      });
    } else if(this.nonceCard) {
      handlePayment(this.nonceCard);
    }
    return false;
  }

  private submitGiftCardpayment(data): boolean | void {
    let that = this;
    this.LogSvr.logInfo(`Submit billing - Gift Card::${this.form.value.method}::card nonce::${this.nonceGiftCard}`, this.farmSlug, 'Online Store', this.farm.email)
    if(this.nonceGiftCard){

      const card = "SQUARE_GIFT_CARD";
      that.paymentService.createGiftPayment(this.nonceGiftCard, that.farm.id, data, that.orderid, that.order, card, 'SQUARE').subscribe((result) => {
        this.LogSvr.logInfo(`Submit billing::${this.form.value.method}::card nonce::${this.nonceCard}`, this.farmSlug, 'Online Store', this.farm.email)
        if (result.meta.code === '45_25_s') {
          that.form.patchValue({ method: 'credit_card' });
          that.flagRemain = true;
          that.gift_amount = +result.data['paymentamountreceived'];
          that.balance = (+result.data['paymentamount'] - +result.data['paymentamountreceived']);

          that.order['orderid'] = result.data['orderid'];
          that.order['id'] = result.data['id'];
          that.isProcessing = false;
          that.createCreditPaymentForm();
          that.paymentForm.build();
        } else {
          that.router.navigate([CONSTANTS.ROUTES.CART_SUCCESS_PUBLIC(that.farmSlug)]);
          that.storage.resetCartStorage(that.farmSlug);
          that.storage.resetTotalStorage(that.farmSlug);
          that.storage.resetNote(that.farmSlug);
          that.storage.resetpickupId(that.farmSlug);
          that.storage.resetBillingInfo(that.farmSlug);
          that.storage.resetOrder(that.farmSlug);
          that.storage.resetPaymentID(that.farmSlug);
        }


      }, (error) => {
        this.LogSvr.logInfo(`Submit billing - Gift Card::ERROR::${error.body.meta.code}::${JSON.stringify(error)}`, this.farmSlug, 'Online Store', this.farm.email);
        if (error.body.meta.code === '47_16_f') {
          that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
        }
        that.giftErrorLog = {};
        if (error.body.meta.code === '45_23_f') {
          let windowClosedConfig = {
            text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true,
            closeX: true
          };
          that.toast.open(windowClosedConfig);
          return;
        }
        if (error.body.meta.code === '47_30_f') {
          let windowClosedConfig = {
            text: error.body.meta.message,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true,
            closeX: true
          };
          that.toast.open(windowClosedConfig);
          return false;
        }
        if (error.body.meta.code === '47_16_f') {
          that.router.navigate([CONSTANTS.ROUTES.STORE(that.farmSlug)]);
        }
        if (error.body.meta.code === '0_7_f'){
          that.statusSubmit = false;
          that.checkDisablePurchase();
          let message = error.body.extendData || `Your card is invalid. Please verify your card information.`
          if(error.body.extendData && message.indexOf('GENERIC_DECLINE') > -1){
            message = "Your credit card was declined by Square, there seems to be an issue with your card.";
          }
          message = message.replace(';', ',');
          let windowClosedConfig = {
            text: message,
            actions: [
                {
                    label: 'Dismiss',
                    callback: () => {
                    }
                }
            ],
            isError: true,
            closeX: true
        };

        that.toast.open(windowClosedConfig);
        } else {
          let windowClosedConfig = {
            text: `Something went wrong, please try again later or contact us at hello@tend.com. Error Info: ${error.body.meta.code} - ${error.body.meta.message}`,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {
                }
              }
            ],
            isError: true
          };
          that.toast.open(windowClosedConfig);
        }
      });
    }
    else
    return false;
  }

  checkDisablePurchase(){
    return false;
    let result = false;
    if(this.isProcessing)
      result = true;
    if(this.form.value.method == 'credit_card' && !this.nonceCard)
      result = true;
    if(this.form.value.method == 'gift_card' && !this.nonceGiftCard)
      result = true;
    if(this.statusSubmit == false)
      result = true;
    return result;
  }
}

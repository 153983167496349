import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { OfferComponent } from './components/offer/offer/offer.component';
import { CartComponent } from './components/cart/cart/cart.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { OrderSuccessComponent } from './components/cart/order-success/order-success.component';
import { ResetPasswordGuard } from './guards/reset-password.guard';
import { NoFarmComponent } from './components/not-found/no-farm/no-farm.component';
import { NoOfferComponent } from './components/not-found/no-offer/no-offer.component';
import { CONSTANTS } from './model/enums';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { ViewOrderComponent } from './components/settings/view-order/view-order.component';

import { StoreBillingComponent } from './components/public/billing/billing.component';
import { StoreComponent } from './components/public/store/store.component';
import { CartPublicComponent } from './components/public/cart/cart.component';
import { OrderSuccessPublicComponent } from './components/public/order-success/order-success.component';
import { POSStoreComponent } from './components/public/pos/store/store.component';
import { POSCartPublicComponent } from './components/public/pos/cart/cart.component';
import { POSStoreBillingComponent } from './components/public/pos/billing/billing.component';
import { POSOrderSuccessPublicComponent } from './components/public/pos/order-success/order-success.component';
import { FarmGuard } from './guards/farm.guard';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/offer/:${CONSTANTS.ROUTE_PARAM_KEYS.OFFER_ID}`,
    component: OfferComponent,
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/cart`,
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/cart/success/:${CONSTANTS.ROUTE_PARAM_KEYS.ORDER_ID}`,
    component: OrderSuccessComponent,
    canActivate: [FarmGuard],
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: StoreComponent,
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/store/billing`,
    component: StoreBillingComponent,
    canActivate: [FarmGuard],
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/store/cart`,
    component: CartPublicComponent,
    canActivate: [FarmGuard],
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/store/success`,
    component: OrderSuccessPublicComponent,
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/account`,
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/orders/:${CONSTANTS.ROUTE_PARAM_KEYS.ORDER_ID}`,
    component: ViewOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `offers/:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: POSStoreComponent,
    children: [
      {
        path: 'unsubscribe',
        component: UnsubscribeComponent,
      },
    ],
  },
  {
    path: `offers/store/cart/:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: POSCartPublicComponent,
    canActivate: [FarmGuard],
  },
  {
    path: `offers/store/billing/:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: POSStoreBillingComponent,
    canActivate: [FarmGuard],
  },
  {
    path: `offers/store/success/:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: POSOrderSuccessPublicComponent,
  },
  {
    path: `offers/about/:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}`,
    component: AboutComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard],
  },
  { path: "no-farm", component: NoFarmComponent },
  { path: "no-offer", component: NoOfferComponent },
  {
    path: `:${CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG}/about`,
    component: AboutComponent,
  },
  { path: "**", component: NoFarmComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { LogFields } from './log-data.interface';
import { IPaymentLog } from 'src/app/model/requests/payment-log.model';
import { LoggerService } from 'src/app/services/logger.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from 'src/app/services/http.service';

export type LogType = 'Error' | 'Warning' | 'Information';

interface LogEntry {
    type: LogType;
    message: string;
    data: LogFields;
}

enum LoggerEvents {
    Flush = 1
}

export class Logger {
    private readonly ENV_FIELD = 'Environment';
    private readonly VERSION_FIELD = 'Version';
    private readonly ELAPSED_MS_FIELD = 'ElapsedMilliseconds';
    private readonly REQUEST_PATH_FIELD = 'RequestPath';
    private readonly URL_FIELD = 'Url';
    private readonly APP_STATE_FIELD = 'AppState';
    private readonly FARM_SLUG = 'FarmSlug';
    private readonly EMAIL = 'Email';
    private readonly COMPONENT = 'Component';

    private buffer: LogEntry[] = [];
    private flush = new Subject<LoggerEvents>();
    private logSvr: LoggerService
    constructor(private http: HttpClient) {
        this.logSvr = new LoggerService(this.http);
        this.flush
            .pipe(debounceTime(2000), filter((event) => event === LoggerEvents.Flush))
            .subscribe(() => this.flushBuffer());
    }

    public log(type: LogType, message: string, data: LogFields) {
        this.buffer.push({
            type,
            message,
            data
        });
        this.flush.next(LoggerEvents.Flush);
    }

    private flushBuffer() {
        const data = this.buffer.splice(0);

        if (data.length === 0) {
            return;
        }
        const body = data
            .map((entry) => this.buildLogString(entry))
            .reduce((sum, entry) => (sum += entry), '');

        // if (!environment.production) {
            console.log({
                body,
                data
            });
        // } else {
            this.handleEventError({
                msg: body,
                farmSlug: data[0].data.farmSlug,
                source: data[0].data.source || "Global Error Handler",
                feature: data[0].data.feature || "Global Error Handler",
                email: data[0].data.email || data[0].data.url || data[0].data.farmSlug || "Global Error Handler",
                component: data[0].data.component || "Global Error Handler"
            })
        // }
    }

    private buildLogString(entry: LogEntry): string {
        const index = this.buildIndexChunk();
        const body = this.buildBodyChunk(entry);

        return `${index}\n${body}\n`;
    }

    private buildIndexChunk() {
        const date = moment();
        const index = {
            index: {
                _index: `logstash-${date.format('YYYY.M.D')}`,
                _type: 'logevent'
            }
        };

        return JSON.stringify(index);
    }

    private buildBodyChunk(entry: LogEntry) {
        const { type, message, data } = entry;
        const level = type;
        const date = moment();
        const messageTemplate = this.getMessageTemplate();
        const fields = this.getFields(data);
        const body = {
            '@timestamp': `${date.toISOString()}`,
            level,
            messageTemplate,
            message,
            fields
        };

        return JSON.stringify(body);
    }

    private getMessageTemplate() {
        const fields: string[] = [
            this.FARM_SLUG,
            this.ENV_FIELD,
            this.VERSION_FIELD,
            this.ELAPSED_MS_FIELD,
            this.REQUEST_PATH_FIELD,
            this.URL_FIELD,
            this.APP_STATE_FIELD,
            this.EMAIL,
            this.COMPONENT
        ];
        const template = fields.map((field) => `{${field}}`).join(' - ');

        return template;
    }

    private getFields(data: LogFields) {
        return {
            [this.FARM_SLUG]: data.farmSlug,
            [this.ENV_FIELD]: data.environment,
            [this.VERSION_FIELD]: data.appVersion,
            [this.ELAPSED_MS_FIELD]: data.elapsedTime,
            [this.REQUEST_PATH_FIELD]: data.requestPath,
            [this.URL_FIELD]: data.url,
            [this.EMAIL]: data.email,
            [this.COMPONENT]: data.component,
        };
    }

    private handleEventError({ msg, farmSlug, source, feature, email, component }:
        { msg: string, farmSlug: string, source?: string, feature?: string, email?: string, component?: string}) {
        let errVm: IPaymentLog = {
            component: component,
            email: email,
            farmSlug: farmSlug,
            feature: feature,
            info: msg,
            source: source
        };
        this.logSvr.logErrorCard(errVm).subscribe(rec => rec);
    }
}
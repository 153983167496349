import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from 'src/app/model/enums';
import { HttpService } from 'src/app/services/http.service';
import { IOfferResponse, IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { HttpErrorResponse } from '@angular/common/http';
import { DataStorageService } from 'src/app/services/storage.service';
import { StoreService } from 'src/app/services/store.service';
import { OnlineStoreCartService } from 'src/app/services/online-store-cart.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {
    labels = {
        lblHeader: 'About us',
        lblFindUs: 'Find us on the web'
    };

    farmDescription: SafeHtml;
    imageUrls: string[];

    facebookLink: string;
    instagramLink: string;
    twitterLink: string;
    youtubeLink: string;
    website: string;
    linkweb: string;

    facebookIcon = 'fb.svg';
    instagramIcon = 'instagram_logo.svg';
    twitterIcon = 'twitter.svg';
    youtubeIcon = 'youtube.svg';

    existDesc: boolean = true;
    isPOS: boolean = window.location.pathname.startsWith("/offers/");
    constructor(public layout: LayoutService, private route: ActivatedRoute,
                private storage: DataStorageService, private onlineStoreCartService: OnlineStoreCartService,
                private storeService: StoreService, private router: Router, private session: SessionService,
                private domSanitizer: DomSanitizer) { }

    ngOnInit() {
        // this.session.getFarm().subscribe((farm: Farm) => {
        //     if (farm) {
        //         this.initData(farm);
        //     } else {
        this.route.paramMap.subscribe((params) => {
            const farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
            this.storage.resetFarmStorage(farmSlug);
            let entries = this.storage.getCartList(farmSlug) || [];
            this.onlineStoreCartService.initEntries(entries);
            this.loadFarm(farmSlug);
        });
        //     }
        // });
    }

    // initData(farm: Farm) {
    //     this.farmDescription = farm.about;
    //     this.imageUrls = farm.storyImageUrls;
    //     this.facebookLink = farm.facebook;
    //     this.instagramLink = farm.instagram;
    //     this.twitterLink = farm.twitter;
    //     this.youtubeLink = farm.youtube;
    // }

    loadFarm(farmSlug: string) {
        var params = {};
        if (this.isPOS) { params['token'] = farmSlug; }
        if (!this.isPOS) { params['farmSlug'] = farmSlug; }
        this.storeService.getInfoStore(params, this.isPOS).subscribe((response: IOfferFullResponse) => {

          if (!response.data || !response.data.offer || response.data.offer == null) {
            if (response.meta.code === '47_16_f') {
              // return to no offer page
              this.router.navigate([CONSTANTS.ROUTES.NO_OFFER],
                {queryParams: {farmSlug}});
            } else if (response.meta.code === '48_1_f') {
              // return to no farm page
              this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
            }
            return;
          }

            this.session.setFarm(response.data.farm, farmSlug);
            this.storage.setFarm(response.data.farm, farmSlug);
            const farm = this.storage.getFarm(farmSlug);

            if (farm.website) {
              this.website = farm.website.startsWith("http://") ? farm.website.replace("http://", "") : farm.website.startsWith("https://") ? farm.website.replace("https://", "") : farm.website;
              this.linkweb = farm.website.startsWith("http://") || farm.website.startsWith("https://") ? farm.website : "http://" + farm.website;              
            }

            if (!farm.about) {
              this.existDesc = false;
              farm.about = `Welcome to ${farm.name}.`
            }
            this.farmDescription = this.domSanitizer.bypassSecurityTrustHtml(farm.about);
            this.imageUrls = farm.storyImageUrls;
            this.facebookLink = farm.facebook;
            this.instagramLink = farm.instagram;
            this.twitterLink = farm.twitter;
            this.youtubeLink = farm.youtube;

        }, (error: HttpErrorResponse) => {
            console.error(error);
            this.storage.resetFarmStorage(farmSlug);
            this.storage.setFarm(error.error.data.farm, farmSlug);
            this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnsubscribeService } from 'src/app/services/unsubscribe.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.less']
})
export class UnsubscribeComponent implements OnInit {
  isError = false;
  loading = true;
  private farmToken;
  private emailToken;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private unsubSvr: UnsubscribeService
  ) { }

  ngOnInit() {
    const rid = this.route.snapshot.queryParams.rid;
    this.emailToken = rid;
    if(this.route.snapshot['_urlSegment'].segments.length > 1){
      this.farmToken = this.route.snapshot['_urlSegment'].segments[1].path;
      this.unsubSvr.unsubscribeService(this.farmToken,rid).subscribe(rec=>{
        this.isError = false;
        this.loading = false;
        
      },
      err=>{
        this.navigateToOffer();
        this.isError = true;
        this.loading = false;
      })
    }
  }

  navigateToOffer(){
    this.router.navigate(['offers',this.farmToken]);
  }
}

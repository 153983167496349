import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { OnlineStoreCartEntry } from '../model/online-store-cart-entry';

@Injectable({
  providedIn: 'root'
})
export class OnlineStoreCartService {
  private entriesSubject = new BehaviorSubject<OnlineStoreCartEntry[]>([]);
  private entries: OnlineStoreCartEntry[] = [];
  constructor() { }

  initEntries(entries: OnlineStoreCartEntry[]) {
    this.entries = entries;
    this.entriesSubject.next(this.entries);
  }

  addItem(item) {
    this.entries = [...this.entries, item];
    this.entriesSubject.next(this.entries);
  }

  updateItemWithAddedQuantity(id, updatedData: {
    quantity: number,
    price: any,
    imageUrl: string,
    available: number
  }) {
    const index = this.entries.findIndex(x => x.item.id === id);
    let updatedEntry = this.entries[index];
    this.entries = Object.assign([], {
      ...this.entries,
      [index]: {
        ...updatedEntry,
        quantity: updatedEntry.quantity + updatedData.quantity,
        item: {
          ...updatedEntry.item,
          price: updatedData.price,
          imageUrl: updatedData.imageUrl,
          available: updatedData.available,
        }
      }
    });
    this.entriesSubject.next(this.entries);
  }

  updateItem(entryItem) {
    const index = this.entries.findIndex(x => x.item.id === entryItem.id);
    this.entries = Object.assign([], {
      ...this.entries,
      [index]: {
        ...this.entries[index],
        entryItem
      }
    });
    this.entriesSubject.next(this.entries);
  }

  removeItem(id: number) {
    this.entries = this.entries.filter(e => e.item.id != id);
    this.entriesSubject.next(this.entries);
  }

  clearAllEntries() {
    this.entries = [];
    this.entriesSubject.next(this.entries);
  }

  getEntriesSubscription() {
    return this.entriesSubject;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../model/enums';
import { IPaymentLog } from '../model/requests/payment-log.model';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private http: HttpClient) {
    //http.isLoadingIndication = false;
   }

   private logit(logVm: IPaymentLog): Observable<any> {
     return this.http.post(CONSTANTS.API_ROOT + CONSTANTS.API_ENDPOINT.LOG_PAYMENT, logVm).pipe(
      map((body: any) => {
          return body;
      }));
  }

  public logErrorCard(logVm: IPaymentLog): Observable<any>{
    return this.logit(logVm);
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { CONSTANTS } from '../../model/enums';
import { SessionService } from 'src/app/services/session.service';
import { Farm } from 'src/app/model/farm';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { DataStorageService } from 'src/app/services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";
import { OnlineStoreCartService } from 'src/app/services/online-store-cart.service';
import { OnlineStoreCartEntry } from 'src/app/model/online-store-cart-entry';
import { OsOrderWindowDataService } from 'src/app/services/os-order-window-data.service';
import { StoreService } from 'src/app/services/store.service';
import { OrderWindow } from 'src/app/model/responses/order-window';
import * as moment from 'moment';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.less']
})
export class ToolbarComponent implements OnInit, OnDestroy {

    CONSTANTS = CONSTANTS;
    labels = {
        tabOffer: 'Offer',
        tabAbout: 'About us',
        tabStore: 'Store'
    };
    tabs = [
        //     {
        //         path: CONSTANTS.ROUTES.NO_OFFER,
        //         label: this.labels.tabOffer
        //     },
        //     {
        //         path: CONSTANTS.ROUTES.NO_FARM,
        //         label: this.labels.tabAbout
        //     }
    ];
    tabsNoLogin = [
        {
            path: '',
            label: this.labels.tabStore
        },
        {
            path: '',
            label: this.labels.tabAbout
        }
    ];
    farmLogo: string;
    farmName: string;
    farmSlug: string;
    farmLink: string;
    phone: string;
    email: string;
    website: string;
    isPrivate: boolean = false;
    selectedIndex: number = 0;
    navbarOpen = false;
    isPOS: boolean = window.location.pathname.startsWith("/offers/");
    isReadOnly: string;
    public isInNoOfferScreen = false;
    public isInNoFarmScreen = false;
    public entries: OnlineStoreCartEntry[] = [];
    public orderWindow: OrderWindow;
    public windowStatusClass: string;
    public timeRemaining: string;
    private windowCalculationInterval: any;
    constructor(public layout: LayoutService, public session: SessionService,
        public router: Router, public route: ActivatedRoute,
        private storage: DataStorageService, private titleService: Title, private onlineStoreCartService: OnlineStoreCartService,
        private osOrderWindowDataService: OsOrderWindowDataService, private storeService: StoreService) {
        this.router.events.pipe(
            filter(e => e instanceof NavigationStart)
        ).subscribe((e: NavigationStart) => {
            // Add more empty screen if any
            if (e.url.startsWith('/no-offer')) {
                this.isInNoOfferScreen = true;
                return;
            }
            else if (e.url.startsWith('/no-farm')) {
                this.isInNoFarmScreen = true;
                return;
            }
            this.isInNoOfferScreen = false;
            this.isInNoFarmScreen = false;
            if (this.farmSlug === undefined) {
                this.setTabWithoutLogin(e.url);
            }
        });
    }

    ngOnInit() {
        this.session.getFarm().subscribe((farm: Farm) => {
            if (farm) {
                this.farmName = farm.name;
                const isFarmSlugSet = farm.slug === this.farmSlug;
                if (!isFarmSlugSet) {
                    var params = {};
                    if (this.isPOS) { params['token'] = farm.slug; }
                    if (!this.isPOS) { params['farmSlug'] = farm.slug; }
                    this.storeService.getInfoStore(params, this.isPOS)
                        .subscribe(orderWindowData => {
                            this.orderWindow = orderWindowData.data.offer.window;
                            this.calculateWindowDisplayData(this.orderWindow);
                            this.windowCalculationInterval = setInterval(() => {
                                this.calculateWindowDisplayData(this.orderWindow);
                            }, 1000);
                        });
                }
                this.farmSlug = farm.slug;
                this.farmLogo = farm.logoUrl;
                this.phone = farm.phone;
                this.email = farm.email;
                this.website = farm.website;
                if (window.location.pathname.indexOf('offer') !== -1 && !this.isPOS) {
                    this.session.getOffer().subscribe((offer) => {
                        if (offer) {
                            this.isPrivate = true;
                        }
                    });
                    this.tabs = [
                        {
                            path: this.farmSlug,
                            label: this.labels.tabAbout
                        }
                    ];
                    this.selectedIndex = 0;

                } else {
                    this.tabs = [
                        {
                            path: CONSTANTS.ROUTES.STORE(this.farmSlug),
                            label: this.labels.tabStore
                        },
                        {
                            path: this.farmSlug,
                            label: this.labels.tabAbout
                        }
                    ];
                    this.setSelectedMenuItem();
                }
            }
            // setTimeout(() => {
            //     if (!this.isPrivate && !this.session.getIsLoggedIn()) {
            //         this.setTabWithoutLogin();
            //     }
            // });
        });
        this.route.queryParams.subscribe(params => {
            const farmSlug = params[CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG];
            if (!farmSlug) { return; }

            var farm = this.storage.getFarm(farmSlug);
            if (!farm) { return; }
            this.farmName = farm.name;
            this.farmSlug = farm.slug;
            this.farmLogo = farm.logoUrl;
            this.phone = farm.phone;
            this.email = farm.email;
            this.website = farm.website;
        });

        this.onlineStoreCartService.getEntriesSubscription().subscribe(entries => {
            this.entries = entries;
        });
    }

    ngOnDestroy(): void {
        if (this.windowCalculationInterval) {
            clearInterval(this.windowCalculationInterval);
        }
    }

    calculateWindowDisplayData(window: OrderWindow) {
        const now = (new Date()).getTime();
        const isWindowClosed = now < window.open || now >= window.close;
        this.windowStatusClass = isWindowClosed ? 'closed' : 'open';
        this.timeRemaining = this.getTimeRemaining(isWindowClosed, window, now);
    }

    getTimeRemaining(isClosed: boolean, window: OrderWindow, now: number): string {
        let duration;
        let prefix = '';
        let suffix = '';
        if (isClosed) {
            const date = new Date(window.open);
            const showDate = moment(date).format("dddd");
            const time = moment(date).format('hh:mm a')
            return 'Open on' + ' ' + showDate + ' at ' + time;
            // duration = moment.duration(moment(window.open).diff(now));
            // prefix = 'Opening in ';
        } else {
            duration = moment.duration(moment(window.close).diff(now));
            suffix = ' remaining';
        }
        if (duration.as('days') > 1) {
            return `${prefix}${Math.ceil(duration.as('days'))} days${suffix}`
        } else if (duration.as('days') == 1) {
            return '1 day remaining';
        }
        else if (duration.as('hours') >= 2) {
            return `${prefix}${Math.floor(duration.as('hours'))} hours${suffix}`;
        } else if (duration.as('hours') >= 1) {
            return `${prefix}1 hour${suffix}`;
        } else if (duration.as('minutes') >= 5) {
            return `${prefix}<1 hour${suffix}`;
        } else if (duration.as('seconds') >= 5) {
            return `${prefix}<5 minutes${suffix}`;
        } else {
            return closed ? 'Opening...' : 'Closing...';
        }
    }

    setTabWithoutLogin(path) {
        var reg = new RegExp("^\/([^\/]+)(\/([^\/]+))*\/?$", "i"), exec = reg.exec(path);
        var slug = exec[1];
        if (this.isPOS) {
            slug = exec[3];
        }
        var params = {};
        if (this.isPOS) { params['token'] = slug; }
        if (!this.isPOS) { params['farmSlug'] = slug; }
        this.storeService.getInfoStore(params, this.isPOS)
            .subscribe(orderWindowData => {
                const meta_code = orderWindowData.meta.code;
                this.session.setFarmMetaCode(meta_code);
                this.orderWindow = orderWindowData.data.offer.window;
                this.calculateWindowDisplayData(this.orderWindow);
                this.windowCalculationInterval = setInterval(() => {
                    this.calculateWindowDisplayData(this.orderWindow);
                }, 1000);
                this.isReadOnly = orderWindowData.data.readOnly;
            }, (error: HttpErrorResponse) => {
                const meta_code = error.error.meta.code;
                this.session.setFarmMetaCode(meta_code);
            });
        this.tabsNoLogin[0].label = 'Store';
        this.setSelectedMenuItem();
        // Farm has no data
        this.storage.getFarmBehaviourSubject().subscribe(farm => {
            setTimeout(() => {
                this.orderWindow = this.storage.getWindow(slug).window;
            });
            this.tabsNoLogin[0].label = 'Store';
            this.setSelectedMenuItem();
            if (!farm || Object.keys(farm).length === 0) {
                const farmFromStorage = this.storage.readData('currentFarm', slug);
                if (farmFromStorage === null || Object.keys(farmFromStorage).length == 0) {
                    this.setToolbarInfo(farm);
                    return;
                }
                this.storage.setFarm(farmFromStorage, slug);
                return;
            }


            this.tabsNoLogin[0].path = this.isPOS ? CONSTANTS.ROUTES.PRIVATE_STORE(farm.slug) : CONSTANTS.ROUTES.STORE(farm.slug);
            this.tabsNoLogin[1].path = this.isPOS ? CONSTANTS.ROUTES.PRIVATE_ABOUT(farm.slug) : CONSTANTS.ROUTES.ABOUT(farm.slug);
            this.farmLink = this.isPOS ? CONSTANTS.ROUTES.PRIVATE_STORE(farm.slug) : CONSTANTS.ROUTES.STORE(farm.slug);

            this.setToolbarInfo(farm);
        });
        // Farm has no data
        //   setTimeout(() => {
        //   });
    }

    getAboutPath() {
        return `/${CONSTANTS.ROUTES.ABOUT(this.farmSlug)}`;
    }

    setSelectedMenuItem(link = null) {
        this.selectedIndex = 1;
        if (/\/store\/cart\//i.test(link)) {
            this.selectedIndex = 0;
        }
        if (window.location.pathname.indexOf('about') === -1) {
            this.selectedIndex = 0;
        }
    }

    onWebsiteClicked() {
        var openingUrl = this.website.startsWith('http')
            ? this.website : `http://${this.website}`;
        window.open(openingUrl, 'blank');
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

    getSubtotal() {
        const total = this.entries.reduce((tempTotal, cartEntry, index, items) => {
            return tempTotal + (cartEntry.quantity * cartEntry.item.price);
        }, 0);
        return total;
    }

    getCartLink() {
        if (!this.isReadOnly) {
            if (this.isPOS) {
                return `/${CONSTANTS.ROUTES.PRIVATE_CART_PUBLIC(this.farmSlug)}`;
            }
            return `/${CONSTANTS.ROUTES.CART_PUBLIC(this.farmSlug)}`;
        }
    }

    private setToolbarInfo(farm) {
        this.farmName = this.getFarmValue(farm, 'name');
        this.farmSlug = this.getFarmValue(farm, 'slug');
        this.farmLogo = this.getFarmValue(farm, 'logoUrl');
        this.phone = this.getFarmValue(farm, 'phone');
        this.email = this.getFarmValue(farm, 'email');
        this.website = this.getFarmValue(farm, 'website');
        let title = this.farmName ? `Online Store | ${this.farmName}` : 'Online Store';
        if (this.isPOS) {
            title = this.farmName ? `Offer Sheet | ${this.farmName}` : 'Offer Sheet';
        }
        this.titleService.setTitle(title);
    }

    private getFarmValue(farm, key) {
        return farm ? farm[key] : farm;
    }
}

import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../model/enums';


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private http: HttpService,
    ) { }

    getClientSecret(amount: Number, farmSlug: String) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.GET_CLIENT_SECRET + `?slug=${farmSlug}`, {amount: amount}).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    stripeValidation(farmSlug: number) {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.STRIPE_VALIDATION + `?farmId=${farmSlug}`).pipe(
            map((body: any) => { return body; })
        )
    }

    createPayment(cardKey:string,farmid:any,data : any,orderid:string,order:any) {
        var obj = data;
        obj.farmid = farmid;
        obj.sourceId = cardKey;
        obj.orderid = orderid;
        obj.order = order;
        // obj.sourceId = "cnon:card-nonce-ok";
        // var tt = parseFloat(total) * 100;
        // obj.amountMoney = { "amount": tt, "currency": "USD" };

        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CREATE_PAYMENT, obj).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    createGiftPayment(cardKey:string,farmid:any,data : any,orderid:string,order:any, card: any, paymentSourceType: string, cardToken?: any) {
        var obj = data;
        obj.farmid = farmid;
        obj.orderid = orderid;
        obj.order = order;
        obj.cardType  = card;
        obj.paymentSourceType = paymentSourceType;
        obj.sourceId = cardKey;
        obj.cardToken = cardToken;
        obj.cardId = cardKey;
        // obj.sourceId = "cnon:card-nonce-ok";
        // var tt = parseFloat(total) * 100;
        // obj.amountMoney = { "amount": tt, "currency": "USD" };

        return this.http.postResponse(CONSTANTS.API_ENDPOINT.CREATE_GIFT_PAYMENT, obj).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    createOrder(data) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CREATE_ORDER, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    checkSoldOut(data) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.CHECK_SOLD_OUT, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getCountry() {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_COUNTRY).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getState() {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_STATE).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    sendOrderMetaData(data) {
        return this.http.post<any, any>(CONSTANTS.API_ENDPOINT.SEND_ORDER_META_DATA, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    privateGetCustomer(data) {
        return this.http.get<any>(CONSTANTS.API_ENDPOINT.PRIVATE_GET_CUSTOMER, data).pipe(
            map((body: any) => { return body; })
        );
    }

    privateCheckSoldOut(data) {
        return this.http.post<any,any>(CONSTANTS.API_ENDPOINT.PRIVATE_CHECK_SOLD_OUT, data).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    privateCreateGiftPayment(cardKey:string,farmid:any,data : any,orderid:string,order:any, card: any) {
        var obj = data;
        obj.farmid = farmid;
        obj.sourceId = cardKey;
        obj.orderid = orderid;
        obj.order = order;
        obj.cardType  = card;

        return this.http.postResponse(CONSTANTS.API_ENDPOINT.PRIVATE_CREATE_GIFT_PAYMENT, obj).pipe(
            map((body: any) => { return body; })
        );
    }
}

import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { LogFields } from './log-data.interface';
import { HttpService } from 'src/app/services/http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    private logger: Logger;
    constructor(
        private http: HttpClient
    ) { 
        this.initialize();
    }

    public initialize() {
        this.logger = new Logger(this.http);
    }

    public logHttpInfo(info: any, elapsedTime: number, requestPath: string) {
        const url = location.href;
        const logFields: LogFields = {
            requestPath,
            elapsedTime,
            url,
        };

        this.logger.log('Information', `${info}`, logFields);
    }

    public logError(errorMsg: string, farmSlug?: string, component?: string, email?: string) {
        const url = location.href;

        const logFields: LogFields = {
            requestPath: '',
            elapsedTime: 0,
            url: url,
            farmSlug,
            component,
            email
        };

        this.logger.log('Error', errorMsg, logFields);
    }

    public logInfo(info: any, farmSlug?: string, component?: string, email?: string) {
        const url = location.href;

        const logFields: LogFields = {
            requestPath: '',
            elapsedTime: 0,
            url,
            farmSlug,
            component,
            email
        };

        this.logger.log('Information', info, logFields);
    }
}
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { CONSTANTS } from '../model/enums';
import { map } from 'rxjs/operators';
import { OrderWindow } from '../model/responses/order-window';

@Injectable({
    providedIn: 'root'
})

export class OsOrderWindowDataService {
    constructor(private httpService: HttpService) {}

    getOrderWindow(farmSlug: string) {
        return this.httpService.get<OrderWindow>(CONSTANTS.API_ENDPOINT.GET_ORDER_WINDOW, {
            farmSlug
        }, '47_15_s');
    }
}
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IOfferResponse, IOfferFullResponse } from '../model/responses/offer-response';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private http: HttpService,
    ) { }

    getOffer(farmSlug: string, offersheetId: number) {
        return this.http.get<IOfferResponse>(CONSTANTS.API_ENDPOINT.GET_OFFER, {
            farmSlug,
            offersheetId: offersheetId.toString()
        }).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getPublicOffer(params) {
        return this.http.getfull<IOfferFullResponse>(CONSTANTS.API_ENDPOINT.GET_PUBLIC_OFFER,
            params
        ).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getInfoStore(params, isPrivate = false) {
        var route = CONSTANTS.API_ENDPOINT.GET_INFO_STORE;
        if (isPrivate) {
            route = CONSTANTS.API_ENDPOINT.GET_PRIVATE_INFO_STORE;
        }
        return this.http.getfull<IOfferFullResponse>(route,
            params
        ).pipe(
            map((body: any) => {
                return body;
            })
        );
    }

    getItemStore(params, isPrivate = false) {
        var route = CONSTANTS.API_ENDPOINT.GET_ITEM_STORE;
        if (isPrivate) {
            route = CONSTANTS.API_ENDPOINT.GET_PRIVATE_ITEM_STORE;
        }
        return this.http.getfull<IOfferFullResponse>(route,
            params
        ).pipe(
            map((body: any) => {
                return body;
            })
        );
    }
}

import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

import * as _ from 'lodash';

@Directive({
    selector: `[numberSpecialDirective]`,
    providers: [NgModel, DecimalPipe, CurrencyPipe]
})
export class NumberSpecialDirective implements OnInit {
	private characterAllow = [];
	_type;
    @Input() set nsdType(value) {
        this._type = value;
    }

	constructor() { }

    @HostListener('keypress', ['$event'])
    onKeyPress(event) {
        let key = event.key;
        if (this.characterAllow.indexOf(key) === -1) {
            event.preventDefault();
        }
    }

    ngOnInit() {
		switch(this._type) {
			case "phone":
				this.characterAllow = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '+', '(', ')'];
				break;
			default:
				this.characterAllow = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				break;
		}
	}
}

import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { LogService } from '../logger/log.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private injector: Injector,
        private zone: NgZone) {
        super();
    }

    public handleError(error) {
        const logService: LogService = this.injector.get(LogService);
        const message = error.message ? error.message : error.toString();
        const stack = error.stack ? error.stack : 'No stack trace available';

        if (error.status) {
            error = new Error(message);
        }
        
        this.zone.run(() => {
            const errorTraceStr = `Error message: ${message}. Stack trace: ${stack}`;
            logService.logError(errorTraceStr);
        });
    }
}
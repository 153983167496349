import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-pos-no-offer',
    templateUrl: './no-offer.component.html',
    styleUrls: ['./no-offer.component.less']
})
export class POSNoOfferComponent implements OnInit {
    @Input()
    metaCode: string;
    icon = 'produce-circle.svg';
    heading = 'Can\'t find that offer sheet';
    message = 'Whoops, looks like the offer sheet you are looking for is no longer available. Please access the farm\'s <span class="fl-link-color">current offering.</span>';

    constructor() { }

    ngOnInit() { }
}

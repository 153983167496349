import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';
import { HttpService } from 'src/app/services/http.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { Farm } from 'src/app/model/farm';
import { Offer } from 'src/app/model/offer';
import { BUTTON_COLOR_DEFAULT, CONSTANTS, PAYMENT_METHOD } from 'src/app/model/enums';
import { DataStorageService } from 'src/app/services/storage.service';
import { PaymentService } from 'src/app/services/payment.service';
import { StoreService } from 'src/app/services/store.service';
import { IOfferFullResponse } from 'src/app/model/responses/offer-response';
import { HttpErrorResponse } from '@angular/common/http';
import { OnlineStoreCartService } from 'src/app/services/online-store-cart.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-cart-public',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class POSCartPublicComponent implements OnInit, OnDestroy {

  labels = {
    colName: 'Name',
    colQuantity: 'Quantity',
    colUnits: 'Units',
    colPrice: 'Price',
    colAmount: 'Amount',

    lblShoppingCart: 'Shopping Cart',
    lblPlaceholderNotes: 'Leave a comment',
    lblNotes: 'Add Note',
    lblSubtotal: 'Subtotal',
    lblTax: 'Tax',
    lblShipping: 'Delivery',
    lblTotal: 'Total',
    lblCartEmpty: 'Your shopping cart is empty!',
    lblCartEmptyDesc: 'Optional copy text if we want to add something about adding to the cart or anything we think.',
    lblInvoice: 'You will be invoiced for your order',
    lblNotAvailable: 'Not available',
    lblPickupLocation: 'Pickup location',
    lblSelectLocation: 'Select a pickup location',
    lblPaymentMethod: 'Payment Method',
    lblFree: 'FREE',

    btnSendOrder: 'Continue'
  };

  COLOR_DEFAULT_BACKGROUND = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_BACKGROUND;
  COLOR_DEFAULT_TEXT = BUTTON_COLOR_DEFAULT.COLOR_DEFAULT_TEXT;
  cartEntries: any = [];
  cartForm: FormGroup;
  pickupLocationFormControl: number;
  paymentMethodFormControl: number;
  errors: any = {};
  note;
  pickupLocationId: any;
  paymentMethodID: any;
  subtotal;
  tax = 0;
  shipping = 0;
  total;
  farm: Farm;
  offer: Offer;
  farmSlug: any;
  orderid: string;
  currTax = 0;
  currShipping: any;
  currInstruction: any;
  orderamountminimum:any;
  refToast:any;
  paymentMethodMsg: any;

  navStart: Observable<NavigationStart>;
  navStartSubscription: Subscription;
  farmSubscription: Subscription;
  offerSubscription: Subscription;
  private onlineStoreCartServiceSubscription: Subscription;
  private toastServiceSubcription: Subscription;
  constructor(private cart: CartService, private formBuilder: FormBuilder, private router: Router,
    public layout: LayoutService, public session: SessionService, private storage: DataStorageService,
    private http: HttpService, private toast: ToastService, public route: ActivatedRoute,
    private paymentService: PaymentService, private onlineStoreCartService: OnlineStoreCartService,private pipe: CurrencyPipe) {
  }

  ngOnInit() {
    this.navStart = this.router.events.pipe(filter(event => event instanceof NavigationStart)) as Observable<NavigationStart>;
    this.navStartSubscription = this.navStart.subscribe(() => {
      this.cart.removeEmpties();
    });

    this.cartForm = this.formBuilder.group({
      quantities: '',
      pickupLocation: ''
    });

    this.route.paramMap.subscribe((params) => {
      this.farmSlug = params.get(CONSTANTS.ROUTE_PARAM_KEYS.FARM_SLUG);
      const cartEntries = this.storage.getCartList(this.farmSlug) || [];
      this.onlineStoreCartService.initEntries(cartEntries);
      this.note = (this.storage.getNote(this.farmSlug) || {})['note'];
      const farm = this.storage.getFarm(this.farmSlug) || [];
      this.farm = farm;
      this.farm.pickupLocations = [];
      this.farm.paymentMethods = [];
      var offerwindowid = this.storage.getWindow(this.farmSlug)['window']['id'];
      this.loadPickUpLocations(this.farmSlug, offerwindowid);
      this.loadPaymentMethods(this.farmSlug, offerwindowid);
      this.orderamountminimum = this.storage.getWindowInfo(this.farmSlug)['orderamountminimum'];
    });
    let isFirstTime = true;

    this.onlineStoreCartServiceSubscription = this.onlineStoreCartService.getEntriesSubscription()
      .subscribe(entries => {
        this.cartEntries = [...entries].sort((a,b) => a.item.name.localeCompare(b.item.name));
        this.cartEntries.forEach((item, index) => {
          item['checkedSoldout'] = false;
          item.quantity = item.quantity === null ? 1 : item.quantity;
          if (item.quantity > item.item.available) {
            item.quantity = item.item.available;
          }
          item['amount'] = +item.quantity * +item.item.price;

          this.currTax += item.item.tax;
        });


        this.calculateTotal();
        if(isFirstTime){
          this.showToastOrderMin();
          isFirstTime = false;
        }
      });

      // listener when the toast closed
      this.toastServiceSubcription = this.toast.getEventSubject().subscribe(item => {
        this.showToastOrderMin();
      });
  }

  ngOnDestroy() {
    // this.navStartSubscription.unsubscribe();
    // this.farmSubscription.unsubscribe();
    // this.offerSubscription.unsubscribe();
    if(this.refToast) {
      this.refToast.dismiss();
    }
    this.onlineStoreCartServiceSubscription.unsubscribe();
    this.toastServiceSubcription.unsubscribe();
  }

  getCartStatus() {
    return `Cart (${this.cartEntries.length})`;
  }

  changeNote() {
    this.storage.setNote({note: this.note}, this.farmSlug);
  }

  changeQuantity(item, i) {
    this.onlineStoreCartService.updateItem(item);
    const cartEntries = this.onlineStoreCartService.getEntriesSubscription()
      .getValue();
    this.storage.setCartList(cartEntries, this.farmSlug);
  }

  stepQuantity(flag, data) {
    let max = data.item.available;
    let price = data.item.price;

    flag === 'asc' ? data.quantity++ : data.quantity--;
    if (data.quantity < 1) {
      data.quantity = 1;
    }
    if (data.quantity > +max) {
      data.quantity = +max;
    }

    data['amount'] = +data.quantity * +price;
    this.calculateTotal();
    this.storage.setCartList(this.cartEntries, this.farmSlug);

  }

  onBlurQuantity(item, i) {
    this.cartEntries.forEach((item, index) => {
      item['amount'] = +item.quantity * +item.item.price;
    });
    this.calculateTotal();
    this.storage.setCartList(this.cartEntries, this.farmSlug);
  }

  changePickupLocation(event?) {
    const value = event ? event.id : this.pickupLocationFormControl;
    const index = this.farm.pickupLocations.findIndex(x => x.id === value);
    // if not choose yet Pick up location
    this.pickupLocationId = value;
    if (index != -1) {
      this.errors['pickup-location'] = false;
      this.storage.setpickupId(this.farm.pickupLocations[index], this.farmSlug);
      var numberOfLineBreaks = this.farm.pickupLocations[index]['isinstruction'] ? this.farm.pickupLocations[index]['instruction']['content'] : '';
      if(numberOfLineBreaks){
        this.currInstruction = numberOfLineBreaks.replace(/\r?\n/g, "<br />");
      }
    }
    // this.currTax = index != -1 ? this.farm.pickupLocations[index]['tax'] : 0;
    this.currShipping = index != -1 ? this.farm.pickupLocations[index]['price'] : 0;
    this.calculateTotal();
  }

  changePaymentMethod(event?) {
    const value = event ? event.id : this.paymentMethodFormControl;
    const index = this.farm.paymentMethods.findIndex(x => x.id === value);

    if (index != -1) {
      this.errors['payment-method'] = false;
      this.storage.setPaymentID(this.farm.paymentMethods[index], this.farmSlug);
      this.paymentMethodMsg = this.farm.paymentMethods[index].message;
      // -: var numberOfLineBreaks = this.farm.paymentMethods[index]['isinstruction'] ? this.farm.paymentMethods[index]['instruction']['content'] : '';
      // -: this.currInstruction = numberOfLineBreaks.replace(/\r?\n/g, "<br />");
    }

    this.paymentMethodID = value;
    this.calculateTotal();
  }

  deleteCartEntry(entry, index) {
    let updatedCartEntries = null;
    this.toast.open({
      text: `<p class="text-bold plr">(${entry.quantity}) ${entry.item.name}</p> <p class="text-subheader-sd plr">removed from your cart</p>`,
      actions: [
        {
          label: 'Undo',
          callback: () => {
            this.onlineStoreCartService.addItem(entry);
            updatedCartEntries = this.onlineStoreCartService.getEntriesSubscription().getValue();
            this.storage.setCartList(updatedCartEntries, this.farmSlug);
          }
        },
      ]
    }, true);
    this.onlineStoreCartService.removeItem(entry.item.id);
    updatedCartEntries = this.onlineStoreCartService.getEntriesSubscription().getValue();
    this.storage.setCartList(updatedCartEntries, this.farmSlug);
  }

  getSubtotal() {
    const total = this.cartEntries.reduce((tempTotal, cartEntry, index, items) => {
      return tempTotal + (cartEntry.quantity * cartEntry.item.price);
    }, 0);
    return total;
  }

  getTax() {
    const totalTax = this.cartEntries.reduce((tempTotal, cartEntry, index, items) => {
      return tempTotal + (cartEntry.quantity * cartEntry.item.tax);
    }, 0);
    return totalTax;
  }

  isDisabledContinueBtn = () => {
    if (this.cartEntries.length === 0 || (this.orderamountminimum && this.subtotal && this.subtotal < this.orderamountminimum)) return true;
    return false;
  }

  private calculateTotal() {
    this.subtotal = this.getSubtotal();
    this.tax = this.getTax();
    this.shipping = this.currShipping;
    this.total = this.subtotal + this.tax + this.shipping;
    const data = {
      subtotal: this.subtotal,
      total: this.total,
      tax: this.tax,
      shipping: this.shipping,
      orderid: this.orderid
    }
    this.storage.setTotal(this.farmSlug, data);
  }

  private getCurrencyCode(value, degit = '0.2-2'){
    return this.pipe.transform(value,(this.farm.currencyCode === 'CAD' ? 'Can$' : this.farm.currencyCode),'symbol',degit);
  }

  payment() {
    this.errors['pickup-location'] = !this.pickupLocationFormControl;
    this.errors['payment-method'] = !this.paymentMethodFormControl;
    if (this.errors['pickup-location'] ||
        this.errors['payment-method']) { return; }
    // if(this.orderamountminimum && this.subtotal < this.orderamountminimum && this.subtotal){
    //   this.refToast = this.toast.open({
    //     text: `The minimum order amount is ` + this.getCurrencyCode(this.orderamountminimum),
    //     duration:200000,
    //     actions: [
    //       {
    //         label: 'Dismiss',
    //         callback: () => {
    //         }
    //       }
    //     ],
    //     isError: true,
    //     closeX: true
    //   });
    //   return;
    // }else{
    //   if(this.refToast) {
    //     this.refToast.dismiss();
    //   }
    // }
    var that = this;
    const data = {
      pickuplocationid: this.pickupLocationId,
      paymentmethodid: this.paymentMethodID,
      paymentamount: this.total,
      subtotalprice: this.subtotal,
      tax: this.tax,
      shipping: this.shipping,
      farmid: this.farm.id,
      orderid: this.orderid,
      note: this.note,
      notefromcustomer: this.note,
      itemdetail: [
      ],
      token: this.farmSlug
    };
    this.cartEntries.forEach((cart, index) => {
      var item = cart.item;
      data.itemdetail.push({
        itemid: item.id,
        itemdetailid: item.itemDetailId,
        packunitname: item.unit.name,
        subtotalprice: (cart.quantity * item.price),
        itemname: item.name,
        quantity: cart.quantity || 0,
        price: item.price
      });
    });
    //validation for total too large
    if(Number(this.total) >= 50000 &&  this.paymentMethodFormControl == PAYMENT_METHOD.CREATE_CARD){
      let windowClosedConfig = {
        text: `Your total cannot be more than  ${this.getCurrencyCode(50000,'0.0-0')}`,
        actions: [
          {
            label: 'Dismiss',
            callback: () => {
            }
          }
        ],
        isError: true,
        closeX: true
      };
      that.toast.open(windowClosedConfig);
      return;
    }

    this.storage.setOrder(data,this.farmSlug);

    this.paymentService.privateCheckSoldOut(data).subscribe((response) => {
      this.router.navigate([CONSTANTS.ROUTES.PRIVATE_PUBLIC_CHECK_OUT(this.farmSlug)]);
    }, (error) => {
      if (error.body.meta.code === '47_16_f') {
        this.router.navigate([CONSTANTS.ROUTES.PRIVATE_STORE(this.farmSlug)]);
      }

      if (error.body.meta.code === '45_21_f') {
        let windowClosedConfig = {
          text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
          actions: [
            {
              label: 'Dismiss',
              callback: () => {
              }
            }
          ],
          isError: true,
          closeX: true
        };
        that.toast.open(windowClosedConfig);

        this.cartEntries.forEach((x, index) => {
            if (x.quantity === 0 || x.quantity === null) {
              x['checkedSoldout'] = true;
            }

        });
      }

      if (error.body.meta.code === '45_23_f') {
        const rcheck = this.checkInfoCart(error.body);
        if (rcheck['invalid-qty'].length) {
          let config = {
            text: `There were item(s) in your cart that are no longer available. Please remove them and resubmit your order`,
            actions: [
              {
                label: 'Dismiss',
                callback: () => {}
              }
            ],
            isError: true,
            closeX: true
          };
          that.toast.open(config);
        }
        if (rcheck['invalid-price'].length) {
          let config = {
            text: `Some of the items in your cart are updated with new prices. Please press Refreshes to see changes.`,
            actions: [
              {
                label: 'Refreshes',
                callback: () => {
                  this.refreshInfoCart();
                }
              }
            ],
            isError: true,
            duration: 2147483647,
            closeX: true
          };
          that.toast.open(config);
        }
        if (error.body.data.itemSoldouts.length > 0) {
          error.body.data.itemSoldouts.forEach((it, index) => {
            this.cartEntries.forEach((x, index) => {
              if (!it.resultLimitOrder) {
                if (it.itemdetailid === x.item['itemDetailId']) {
                  x['checkedSoldout'] = true;
                }
              }
            });
            if (!it.resultLimitOrder) {
              const index = this.cartEntries.findIndex(x => x.item['itemDetailId'] === it.itemdetailid);
              this.cartEntries[index]['checkedSoldout'] = true;
            }
          });

        }
      }
    });
  }

  loadPickUpLocations(farmSlug: string, windowId? : any) {
    this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_PRIVATE_PICKUP_LOCATIONS, {
      token: farmSlug, windowId
    }).subscribe((response: any) => {
      const farm = this.storage.getFarm(this.farmSlug) || [];
      farm.pickupLocations = response || [];
      this.farm = Object.assign(this.farm, farm);
      var cartInfo = this.storage.getTotal(this.farmSlug) || {};
      this.orderid = cartInfo.orderid || null;

      this.pickupLocationFormControl = (this.storage.getpickupId(this.farmSlug) || {})['id'];

      if (farm.pickupLocations.length === 0 || farm.pickupLocations.findIndex(item => item.id === this.pickupLocationFormControl) === -1) {
        this.pickupLocationFormControl = undefined;
      }

      this.changePickupLocation();
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        if (error.error.meta.code === '47_16_f') {
          this.session.setFarm(error.error.data.farm, farmSlug);
          this.router.navigate([CONSTANTS.ROUTES.NO_OFFER]);
        } else {
          this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        }
      } else {
        console.error(error);
        this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
      }
    });
  }

  loadPaymentMethods(farmSlug: string, windowId? : any) {
    this.http.get<any>(CONSTANTS.API_ENDPOINT.GET_PRIVATE_PAYMENT_METHODS, {
      token: farmSlug, windowId
    }).subscribe((response: any) => {
      const farm = this.farm || this.storage.getFarm(this.farmSlug) || [];
      //farm.paymentMethods = response || [];
      farm.paymentMethods = response.map(payMt=>{
        payMt.message = payMt.paymentmethodmsg;
        return payMt;
      });
      this.farm = Object.assign(this.farm, farm);
      var cartInfo = this.storage.getTotal(this.farmSlug) || {};
      this.orderid = cartInfo.orderid || null;

      this.paymentMethodFormControl = (this.storage.getPaymentID(this.farmSlug) || {})['id'];

      if (farm.paymentMethods.length === 0 || farm.paymentMethods.findIndex(item => item.id === this.paymentMethodFormControl) === -1) {
        this.paymentMethodFormControl = undefined;
      }

      this.changePaymentMethod();
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        if (error.error.meta.code === '47_16_f') {
          this.session.setFarm(error.error.data.farm, farmSlug);
          this.router.navigate([CONSTANTS.ROUTES.NO_OFFER]);
        } else {
          this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
        }
      } else {
        console.error(error);
        this.router.navigate([CONSTANTS.ROUTES.NO_FARM]);
      }
    });
  }

  checkInfoCart(bodySoldout) {
    const list = bodySoldout.data['itemSoldouts'];
    let invalid_qty = [], invalid_price = [];
    list.forEach(item_new => {
      const item_org = this.cartEntries.filter(entry => { return entry.item['itemDetailId'] === item_new['itemdetailid']; })[0];
      if (item_org['quantity'] > item_new['stockquantity']) {
        item_org['quantity-limit'] = item_new['stockquantity'];
        invalid_qty.push(item_new);
      }
      if (item_org.item['price'] !== item_new['price']) {
        item_org.item['price-new'] = item_new['price'];
        invalid_price.push(item_new);
      }
    });
    return {
      'invalid-qty': invalid_qty,
      'invalid-price': invalid_price
    }
  }

  refreshInfoCart() {
    let changed = false;
    this.cartEntries.forEach(entry => {
      if (entry.item.hasOwnProperty('price-new')) {
        entry.item['price'] = entry.item['price-new'];
        delete entry.item['price-new'];

        this.onlineStoreCartService.updateItem(entry);
        changed = true;
      }
    });
    if (changed) {
      const list = this.onlineStoreCartService
        .getEntriesSubscription().getValue();
      this.storage.setCartList(list, this.farmSlug);
    }
  }

  showToastOrderMin() {
    if (this.orderamountminimum && this.subtotal && this.subtotal < this.orderamountminimum) {
      this.toast.open({
        text: `Minimum ` + this.getCurrencyCode(this.orderamountminimum) + ` is required to check out.`,
        duration: 5000,
        actions: [
          {
            label: 'Dismiss',
            callback: () => {
            }
          }
        ],
        isError: true,
        closeX: true
      });
    }
  }
}
